@use 'sass:math';

$form-custom-size: 22px;
$form-custom-bullet-size: 12px;
$form-custom-check-size: 12px;

$svg-color-selected: #b1d249;

.label {
  @apply block relative;

  > span {
    @apply cursor-pointer block relative select-none align-bottom;

    padding-left: $form-custom-size + 10px;

    &::after,
    &::before {
      @apply block rounded-sm absolute left-0 top-0;
      @apply border-2 border-grey-300 bg-grey-100;
      @apply text-center overflow-hidden;

      content: '';
      height: $form-custom-size;
      line-height: $form-custom-size;
      width: $form-custom-size;
    }

    &::after {
      @apply text-brand-2;
      @apply border-0;
      @apply opacity-0;
    }

    &:hover {

      &::before {
        @apply bg-white border-grey-400 shadow-md;
      }
    }
  }

  input[type='radio'] {
    @apply absolute opacity-0 -z-1;

    + span {

      &::after {
        @apply p-0 bg-grey-400;

        height: $form-custom-bullet-size;
        left: math.div($form-custom-size - $form-custom-bullet-size, 2);
        top: math.div($form-custom-size - $form-custom-bullet-size, 2);
        width: $form-custom-bullet-size;
      }

      &::after,
      &::before {
        @apply rounded-full;
      }

      &:hover {

        &::after {
          @apply opacity-25;
        }
      }
    }

    &:checked + span {

      &::before {
        @apply bg-white border-grey-400;
      }

      &::after {
        @apply bg-brand-2;
        @apply opacity-100;
      }
    }
  }

  input[type='checkbox'] {
    @apply absolute opacity-0 -z-1;

    + span {

      &::after {
        @apply hidden;
      }

      & + .input-icon {
        @apply hidden;
      }
    }

    &:checked + span {

      &::before {
        @apply bg-white border-grey-400;
      }

      // &::after {
      //   @apply opacity-100;
      // }

      & + .input-icon {
        @apply block;
      }
    }

    &:disabled + span {
      @apply cursor-not-allowed;

      &::before {
        @apply bg-grey-200 border-grey-400 shadow-none;
      }

      & + .input-icon {

        .icon {
          @apply text-grey-400;
        }
      }
    }
  }
}
