/* purgecss start ignore */

.b-grid {

  &--preset-2 {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full;

      @screen lg {
        @apply w-1/2;
      }
    }

    .lightbox-content &,
    .body--lightbox & {
      @apply justify-start;

      & > .col {
        @apply mb-4;
      }
    }
  }
}

/* purgecss end ignore */
