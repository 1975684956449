.card {
  $self: &;

  &--preset-4 {
    @apply max-w-md;

    @screen sm {
      @apply flex items-start;
      @apply max-w-content-small;
    }

    #{$self}__body {
      @apply flex flex-wrap flex-auto;
      // @apply py-2;
    }

    #{$self}__title {
      @apply w-full;

      @extend .spacing--preset-s;

      @if $rtl {
        @apply text-right #{!important};
      }

      h3 {
        @extend .title--preset-6;

        a {

          &:hover {
            @apply text-brand-2;
          }
        }
      }
    }

    #{$self}__meta {
      @apply w-full;

      // @extend .spacing--preset-s;

      #{$self}__date {
        @apply opacity-50;
        @extend .text--preset-small;
        @extend .spacing--preset-s;
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 3px;
          + .icon {
            margin-left: 15px;
          }
        }
      }

      .button {
        @extend .spacing--preset-s;
      }
    }
  }
}
