.menu {

  $self: &;

  &--preset-4 {
    @apply h-6 pt-6 w-16 relative z-30 mr-4;
    @apply mt-2;

    // border: 1px solid pink;

    > #{$self}__item {
      @apply block text-left w-full hidden;

      a {
        @apply pl-8 pr-6 py-1 w-full block relative leading-none;

        &:hover {
          @apply text-brand-1;
        }
      }

      &--selected {
        @apply absolute left-0 top-0 block;

        a {

          &::after {
            @apply absolute top-50 right-0;
            @apply bg-contain bg-no-repeat bg-center;
            @apply inline-block w-4 h-4 opacity-75;
            @apply -mr-1;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='rgba(0, 0, 0, .25)' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
            content: '';
            transform: translateY(-50%);
          }
        }
      }
    }

    &:hover {
      @apply z-40;

      // &::before {
      //   @apply absolute block h-20 left-0 right-0 top-0 -z-1;
      //   @apply bg-white rounded-sm shadow-lg;
      //   @apply -m-2 -mx-3;

      //   content: '';
      // }

      > #{$self}__item {
        @apply block;

        a {
          @apply bg-white;
        }

        &--selected {

          a {
            @apply bg-white;
          }

          &::after {
            @apply hidden;
          }
        }
      }
    }
  }
}

.flag {
  @apply w-5 h-4 absolute left-0 top-50 ml-2;
  @apply bg-contain bg-center bg-no-repeat;

  transform: translateY(-50%);

  &--nl {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'%3E%3Cg fill-rule='evenodd' stroke-width='1pt' transform='scale(1.25 .9375)'%3E%3Crect width='512' height='509.8' fill='%23fff' rx='0' ry='0'%3E%3C/rect%3E%3Crect width='512' height='169.9' y='342.1' fill='%2321468b' rx='0' ry='0'%3E%3C/rect%3E%3Cpath fill='%23ae1c28' d='M0 0h512v170H0z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  }

  &--en,
  &--gb {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill-opacity='.7' d='M-85.3 0h682.6v512H-85.3z'%3E%3C/path%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' transform='translate(80) scale(.94)'%3E%3Cg stroke-width='1pt'%3E%3Cpath fill='%23012169' d='M-256 0H768v512H-256z'%3E%3C/path%3E%3Cpath fill='%23fff' d='M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z'%3E%3C/path%3E%3Cpath fill='%23fff' d='M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z'%3E%3C/path%3E%3Cpath fill='%23c8102e' d='M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--de {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'%3E%3Cpath fill='%23ffce00' d='M0 320h640v160H0z'%3E%3C/path%3E%3Cpath fill='%23000001' d='M0 0h640v160H0z'%3E%3C/path%3E%3Cpath fill='%23d00' d='M0 160h640v160H0z'%3E%3C/path%3E%3C/svg%3E");
  }
}
