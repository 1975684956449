.spacing {
  @apply mb-4;

  &--preset-xs {
    @apply mb-1;
  }

  &--preset-s {
    @apply mb-2;
  }

  &--preset-m {
    @apply mb-4;
  }

  &--preset-l {
    @apply mb-6;
  }

  &--preset-xl {
    @apply mb-6;

    @screen md {
      @apply mb-8;
    }

    @screen lg {
      @apply mb-10;
    }
  }

  &--preset-band {
    @apply mb-8;

    @screen md {
      @apply mb-10;
    }

    @screen lg {
      @apply mb-12;
    }
  }
}

// .spacing {

//   &--preset-1 {
//     @apply mb-4;
//   }

//   &--preset-2 {
//     @apply mb-6;
//   }

//   &--preset-3 {
//     @apply mb-2;
//   }

//   &--preset-4 {
//     @apply mb-6;

//     @screen md {
//       @apply mb-8;
//     }

//     @screen lg {
//       @apply mb-10;
//     }
//   }
// }
