.link {

  &--preset-1 {
    @apply text-brand-1 underline;

    &:hover {
      @apply text-brand-2 no-underline;
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }

  &--preset-2 {
    @apply text-brand-2 underline;

    &:hover {
      @apply no-underline;
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }

  &--preset-title {
    @apply text-brand-1 no-underline;

    &:hover {
      @apply underline;
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }

  &--preset-menu {
    @apply text-black;

    &:hover {
      @apply text-brand-1;
    }
  }

  &--preset-footer {

    &:hover {
      @apply opacity-75;
    }
  }

  &--location {
    @apply inline-block py-px;
    @apply border border-transparent;

    a {
      @apply relative inline-block;
      @apply text-black;
      @apply transition-colors duration-100;

      &::after {
        @apply absolute left-100 top-0 bottom-0 w-4 h-full block;
        @apply bg-center bg-no-repeat bg-contain;
        @apply opacity-0;
        @apply transition-all transform translate-x-0 duration-100;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{rgba($brand-color-1, .99)}' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E");
        content: '';
      }

      &:hover,
      &:focus {
        @apply text-brand-1 outline-none;

        &::after {
          @apply opacity-100 translate-x-1;
        }
      }
    }

    &.active,
    &:focus-within {
      @apply p-px px-2;
      @apply bg-brand-3 border border-brand-4;
      @apply transition-all duration-100;

      &:hover {
        @apply pr-6;
      }
    }

    &:focus-within {
      @apply bg-transparent border-dashed pr-6;
    }
  }
}
