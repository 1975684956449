@import 'preset-1', 'preset-2', 'preset-3';

/* purgecss start ignore */
.form.umbraco-forms-form {

  @if $theme =='4' {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05);

    .button--lg {
      padding: 0.75rem 2rem;
    }

    &.form--preset-1 .form__fields {
      margin-bottom: 0.5rem;
    }

    input.text,
    .umbraco-forms-form textarea {
      max-width: 100% !important;
      width: 100%;
    }

    .text-preset-label {
      font-size: .95rem;
      font-weight: 700;
    }

    .interacted.valid {
      border-color: #00a524;
    }

    .interacted.invalid {
      border-color: #c90202;
    }
  }

  .umbraco-forms-page {

    .row-fluid {
      display: flex;
      flex-wrap: wrap;
      margin-left: calc(var(--half-gutter-width) * -1);
      margin-right: calc(var(--half-gutter-width) * -1);
    }

    @include generate-flex-columns();
  }
}

/* purgecss end ignore */