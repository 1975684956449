
/* purgecss start ignore */

.phase {
  $self: &;

  @apply rounded bg-brand-2;
  @apply text-white;
  @apply p-4 pt-8 z-20;
  @apply opacity-100;
  @apply w-full overflow-auto;
  @apply transition-opacity duration-200 ease-in-out;

  max-height: calc(100vh - #{px-to-rem(215px)});
  max-width: px-to-rem(350px);
  transform: translateY(px-to-rem(-110px));

  &::after {
    background-image: linear-gradient(0deg, rgb(0, 20, 137) 0, rgba(0, 20, 137, 0));
    bottom: px-to-rem(0);
    content: '';
    left: px-to-rem(15px);
    right: px-to-rem(25px);

    @apply hidden pointer-events-none;
    @apply absolute;
    @apply h-10;
  }

  &::before {
    background-image: linear-gradient(0deg, rgba(0, 20, 137, 0)  0, rgb(0, 20, 137));
    content: '';
    left: px-to-rem(15px);
    right: px-to-rem(25px);
    top: px-to-rem(0);

    @apply hidden pointer-events-none;
    @apply absolute;
    @apply h-10;
  }

  @include mq(1024, max) {
    @apply fixed right-0;
    @apply rounded-tr-none;
    @apply max-w-full mt-0 max-h-full;

    bottom: px-to-rem(20px);
    left: px-to-rem(40px);
    top: px-to-rem(132px);
    transform: none;
    width: auto;

    &::after {
      bottom: px-to-rem(20px);
      left: px-to-rem(55px);
      right: px-to-rem(10px);

      @apply block fixed;
      @apply h-10;
    }

    &::before {
      left: px-to-rem(55px);
      right: px-to-rem(10px);
      top: px-to-rem(132px);

      @apply block fixed;
      @apply h-10;
    }
  }

  @screen lg {
    @apply px-8 py-6;
    @apply sticky;

    top: px-to-rem(300px);

    &--is-sticky {
      @apply fixed right-auto w-auto;

      top: px-to-rem(300px);
    }
  }

  &--is-hidden {
    @apply opacity-0;
    @apply pointer-events-none;
  }

  &--is-expanded {

  }

  &__title {
    @apply font-700;
    @apply mb-4;

    font-size: px-to-rem(26px);
    line-height: px-to-rem(30px);

    @screen lg {
      font-size: px-to-rem(35px);
      line-height: px-to-rem(40);
    }
  }

  &__items {

  }

  &__item {
    @apply py-4;

    border-bottom: 1px solid rgba(255, 255, 255, .5);

    &:last-child {
      @apply border-none;
    }
  }

  &__item-title {
    @apply font-700;

    font-size: px-to-rem(20px);
    line-height: px-to-rem(24);
  }

  &__item-content {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);

    &.rte {

    }
  }

  &__button {
    @apply p-2;
    @apply uppercase;
    @apply rounded-r-none;
    @apply sticky right-0 z-20;
    @apply opacity-100;
    @apply transition-opacity duration-200 ease-in-out;

    font-size: px-to-rem(12px);
    height: px-to-rem(42px);
    line-height: px-to-rem(16px);
    top: px-to-rem(150px);
    transform: translateX(#{px-to-rem(20px)});
    width: px-to-rem(115px);

    &--is-hidden {
      @apply opacity-0;
      @apply pointer-events-none;
    }

    @screen lg {
      @apply hidden;
    }

    #{$self}__button-icon {

      &--open {
        @apply block;
      }

      &--close {
        @apply hidden;
      }
    }

    &--is-expanded {
      @apply rounded-bl-none;
      @apply z-30 fixed;

      top: px-to-rem(92px);
      transform: none;

      #{$self}__button-icon {

        &--open {
          @apply hidden;
        }

        &--close {
          @apply block;
        }
      }
    }
  }

  &__button-icon {
    @apply w-6 h-6;
    @apply mr-1;
    @apply text-white;

    svg {
      fill: currentColor;
    }

    &--open {

    }

    &--close {

    }
  }

  &__sticky-container {
    @apply absolute;
    @apply right-0;

    top: 0;
    width: px-to-rem(115px);

    @screen lg {
      @apply top-auto right-auto;

      width: auto;
    }
  }
}

/* purgecss end ignore */
