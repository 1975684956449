.b-grid--preset-10 .item {
  @apply mb-4 p-4;
  @apply border;
  @apply rounded;
  @apply text-xs;

  // max-width: 560px;

  @screen xl {
    @apply mb-0;

    & + & {
      @apply mt-4;
    }
  }

  &--preset-1 {
    background: #fff;
    border-color: rgba(0, 0, 0, .1);
  }

  &--preset-2 {
    background: #edea9c;
    border-color: #edea9c;
  }

  .title--item {
    @apply font-1 leading-tight font-700;
    @apply text-base mb-4;

    @screen md {
      @apply text-lg;
    }
  }

  &__content {

    > * {
      @apply mb-0;

      & + * {
        @apply mt-4;
      }
    }
  }

  p {
    @apply text-xs leading-relaxed;
  }

  .title--list {
    @apply text-base font-700 mt-8 -mb-2;
  }

  ul,
  ol {
    @apply border-t;
    @apply text-basesm font-700;

    border-color: rgba(0, 0, 0, .1);

    li {
      @apply border-b py-2;

      border-color: rgba(0, 0, 0, .1);

      a {
        @apply block;
      }
    }
  }

  &__contact {

    img {
      @apply max-w-full;

      max-height: 88px;
    }

    & + & {
      @apply border-t pt-4;

      border-color: rgba(0, 0, 0, .1);
    }

    .title--contact {
      @apply text-sm leading-relaxed font-700;
    }

    a {
      @apply text-brand-2 underline;

      &:hover {
        @apply no-underline;
      }
    }
  }
}
