/* purgecss start ignore */

.figure {
  $self: &;

  @apply inline-block text-center;

  img {
    @apply mx-auto;

    .content &,
    .band--preset-1 .band__content > &,
    .col--content &,
    .col--media & {
      @include mediaPresets();
    }

    .body--IE11 .col & {
      @apply w-full;
    }
  }

  &--no-shadow {

    img {

      .content &,
      .band--preset-1 .band__content > &,
      .col--content &,
      .col--media & {
        @apply shadow-none;
      }
    }
  }

  figcaption {
    @apply p-2;

    @extend .text--preset-caption;
  }

  &--preset-2 {
    @apply relative;

    .item & {
      @apply w-full block m-0;

      img {
        @apply w-full;
      }
    }

    &::after {
      @apply absolute left-0 right-0 bottom-0 top-75 z-10;

      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
      content: '';
    }

    figcaption {
      @apply absolute left-0 right-0 bottom-0 z-20;
      @apply p-4 text-white;

      @extend .title--preset-6;
    }
  }

  &--preset-3 {
    @apply relative;

    &#{ $self}--has-link {
      transition: all ease-in-out 80ms;

      &:hover {
      }
    }

    .item & {
      @apply w-full block m-0;
    }

    #{ $self }__media {
      @apply w-full;
    }

    #{ $self }__link {
      @apply block;
    }

    #{ $self }__caption {
      @apply absolute left-0 right-0 bottom-0 pointer-events-none text-right;
      @apply p-4 text-white;

      text-shadow: 0 1px 5px #4b4b4b;

      &::before {
        @apply absolute left-0 right-0 bottom-0 z-0;

        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .4) 100%);
        content: '';
        top: -10px;

        @screen lg {
          top: -50px;
        }
      }
    }

    #{ $self }__title {
      @apply relative z-10 block text-basesm font-700 text-white mb-1 text-left;

      @screen md {
        @apply text-lg;
      }
    }

    #{ $self }__caption-content {
      @apply relative z-10 mb-3 text-left;
    }

    #{ $self }__read-more {
      @apply relative z-10 pointer-events-auto cursor-pointer;

      text-shadow: none;
    }
  }
}

/* purgecss end ignore */
