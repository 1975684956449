/* purgecss start ignore */

.js-maps__tooltip {
  @apply absolute z-10 left-0 top-0 block bg-brand-2 text-white px-3 py-2;
  @apply opacity-0 transition-opacity duration-100 rounded-sm pointer-events-none;

  transform: translate(-50%, px-to-rem(-60px));
  transform-origin: 50% 100%;

  &::after {
    @apply absolute bottom-0 left-50 w-0 h-0;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid $brand-color-2;
    content: '';
    transform: translate(-50%, 100%);
  }

  &--is-active {
    @apply block opacity-100;
  }
}

/* purgecss end ignore */
