/* purgecss start ignore */

.band--header {

  &--preset-2 {
    @apply flex items-start pb-0 font-500;

    min-height: 42vh;

    @screen md {
      height: 80vh;
      max-height: px-to-rem(860px);
      min-height: px-to-rem(560px);
    }

    h1 {
      text-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
    }

    p {
      text-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
    }

    @include mq(480, max) {

      h1.title--preset-1 {
        font-size: px-to-rem(29px);
      }
    }

    .band__image {
      @apply opacity-100;
    }

    .band__video {
      @apply overflow-hidden block;
      @apply absolute left-50 top-0 z-10;
      @apply w-screen;

      min-height: 42vh;
      transform: translateX(-50%);

      @if $theme =='3' {
        border-bottom: 1.25rem solid black;
      }

      @if $theme =='4' {
        border-bottom: 1.25rem solid var(--theme-color-brand1);
      }


      @screen md {
        height: 80vh;
        max-height: px-to-rem(860px);
        min-height: px-to-rem(560px);
      }

      video {
        @apply absolute left-50 top-50;
        @apply w-full h-full;

        max-width: none;
        object-fit: cover;
        transform: translate(-50%, -50%);

        @media screen and (-ms-high-contrast: none) {
          height: auto;
          width: 150%;
        }

        @supports (-ms-ime-align: auto) {
          height: 100%;
          width: auto;

          @screen lg {
            height: auto;
            width: 100%;
          }
        }
      }
    }

    .band__scroll-icon {
      @apply hidden;

      @screen md {
        @apply absolute h-10 block text-black fill-current my-1 mx-auto w-full z-20;

        bottom: px-to-rem(70px);
      }

      &::before {
        border-radius: px-to-rem(25px);
        box-shadow: inset 0 0 0 2px #fff;
        content: '';
        height: px-to-rem(40px);
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: px-to-rem(26px);
      }

      &::after {
        animation-duration: 3.5s;
        animation-iteration-count: infinite;
        animation-name: scrollAnimation;
        border-radius: px-to-rem(4px);
        box-shadow: inset 0 0 0 2px #fff;
        content: '';
        height: px-to-rem(12px);
        left: 50%;
        position: absolute;
        top: 8px;
        transform: translateX(-50%);
        width: px-to-rem(8px);
      }
    }

    .band__content {
      @apply relative text-left text-white z-20;
      @apply self-end w-full;
      @apply pt-8 pb-20;

      @screen md {
        @apply self-center p-4;
      }

      .title {

        &--preset-1 {
          @apply mb-6;

          @screen md {
            @apply mb-8;
          }
        }

        &--preset-4 {
          @apply font-500;
        }
      }
    }
  }
}

@keyframes scrollAnimation {

  0% {
    opacity: 1;
    top: 8px;
  }

  23% {
    animation-timing-function: cubic-bezier(.36, .07, .19, .97);
    top: 19px;
  }

  36% {
    animation-timing-function: cubic-bezier(.36, .07, .19, .97);
    opacity: 1;
  }

  44% {
    animation-timing-function: ease-out;
    opacity: 0;
  }

  49% {
    top: 19px;
  }

  50% {
    animation-timing-function: ease-out;
    opacity: 0;
    top: 8px;
  }

  58% {
    opacity: 1;
  }
}


/* purgecss end ignore */