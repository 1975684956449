/* purgecss start ignore */

.slider {
  $self: &;

  &--preset-6 {
    @apply w-full overflow-hidden relative pb-10;

    .slider-content {
      @apply text-center mb-8;

      @screen sm {
        @apply pt-4 mb-12;
      }
    }

    .slider {
      @apply block #{!important};
    }

    .tns-ovh {
      // @apply overflow-hidden #{!important};
    }

    .slide {
      @apply opacity-0 px-12 text-center;
      padding-left: 1rem;
      padding-right: 1rem;
      @screen sm {
        flex-direction: row;
        padding-left: 3rem;
        padding-right: 3rem;
      }

      @screen lg {
        .card__body {
          @apply opacity-0;

          transform: translateY(-25%);
          transition: opacity 200ms 300ms, transform 1s;
        }
      }

      &.tns-slide-active {
        @apply opacity-100;

        @screen lg {
          .card__body {
            @apply opacity-100;

            transform: translateY(-50%);
          }
        }
      }
    }

    .tns-controls {
      @apply absolute left-0 right-0 top-50 z-50;

      transform: translateY(-50%);

      &__button {
        @apply border-0 shadow-none m-0;

        background: none;

        &:hover {
          @apply shadow-none;
        }
      }

      button {
        @apply absolute top-50;

        transform: translateY(-50%);

        &[data-controls="prev"] {
          left: -0.5rem;
          @screen sm {
            @apply left-0;
          }
        }

        &[data-controls="next"] {
          right: -0.8rem;
          @screen sm {
            @apply right-0;
          }
        }
      }
    }

    .tns-nav {
      @apply absolute z-20 top-100 left-50 p-0 mt-2;
      @apply transform -translate-x-1/2;

      button {
        @apply bg-grey-300;

        &:hover {
          @apply bg-brand-2;
        }

        &.tns-nav-active {
          @apply bg-brand-2;
        }
      }
    }

    .b-grid & {
      @apply pb-0;

      .slide {
        @apply px-0;

        .figure {
          @apply block;
        }
      }

      .tns-controls {
        @apply w-full pointer-events-none;

        button {
          @apply relative pointer-events-auto;
          @apply h-8 w-8 m-2 rounded-full bg-white;

          // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .3), 0 4px 6px -2px rgba(0, 0, 0, .05);
          transform: translateY(0);

          &[data-controls="next"] {
            @apply float-right;
          }

          span {
            @apply opacity-50;
          }

          &:hover {
            span {
              @apply opacity-100;
            }
          }
        }

        &__button {
          @apply h-6 w-6 m-1;
        }
      }

      .tns-nav {
        @apply absolute bottom-auto top-100 z-50 left-50 p-1;
        @apply m-2;
        @apply transform -translate-x-1/2 -translate-y-12;

        button {
          @apply border-2 border-white bg-white opacity-100;

          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.05);

          &.tns-nav-active {
            @apply bg-brand-2;
          }
        }
      }
    }
    .slide {
      &__item > div {
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
        flex-direction: column-reverse;
        @screen sm {
          flex-direction: row;
        }
      }
      &__content {
        font-size: 1rem;
        padding-right: 0rem;
        @screen sm {
          font-size: 1.5rem;
          padding-right: 2rem;
        }
        
        font-style: italic;

        &--content {
          display: inline;
         
          &::before {
            content: open-quote;
          }
          &::after {
            content: close-quote;
          }
        }
      }
      &__naw {
        text-align: right;
        padding-top: 1rem;
        @screen sm {
          padding-top: 0;
        }
      }
      &__name {
        display: block;
        font-style: normal;
        font-size: 1rem;
        line-height: 0.5;
        &:before {
          content: "—";
        }
      }
      &__function {
        font-style: normal;
        font-size: 0.9rem;
        opacity: 0.75;
      }
      &__image {
        min-width: px-to-rem(150px);
        margin-bottom: 1rem;
        @screen sm {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* purgecss end ignore */
