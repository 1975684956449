.card {
  $self: &;

  &--preset-6#{$self} {
    @apply relative m-0 p-6 pr-10  pt-8 rounded mb-8 text-left;
    @apply border;

    @screen sm {
      @apply flex items-start;
    }

    &:hover {
      @apply bg-brand-1 transition-all duration-150;
      @apply text-white;
      @apply border-brand-1;

      #{$self}__meta-property {
        @apply text-white;
      }
    }

    #{$self}__body {
      @apply flex flex-wrap flex-auto;
    }

    #{$self}__title {
      @apply w-full;

      @if $rtl {
        @apply text-right #{!important};
      }

      h3 {
        @extend .title--preset-6;

        @apply mb-2;

        a {

          &:hover {
            @apply text-brand-2;
          }
        }
      }
    }

    #{$self}__description {
    }

    #{$self}__meta {
      @apply w-full text-sm;
      @apply mb-2 mt-2;

      @screen md {
        @apply flex flex-wrap;
      }
    }

    #{$self}__meta-property {
      @apply inline-flex items-center;
      @apply text-grey-600;
      @apply mr-6;
    }

    #{$self}__meta-icon {
      @apply block;

      width: px-to-rem(13px);

      svg {
        fill: currentColor;
      }
    }
    #{$self}__meta-description {
      @apply ml-2;
    }

    #{$self}__link {
      @apply absolute;

      right: px-to-rem(12px);
      top: 50%;

      a {
      }

      svg {
        @apply w-8 h-8;

        fill: currentColor;
      }
    }
  }
}
