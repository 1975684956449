.marketform {

  &__title {

  }

  &__fieldscontainer {
    @apply mt-2;

    &--radio {

      .input__field {
        @apply inline-flex;
      }

      .label {
        @apply w-auto;
        @apply mr-6;

        input[type='radio'] {

          &:checked + span {

            &::before {
              @apply bg-white border-grey-400 shadow-md;
            }

            &::after {
              @apply block;
              @apply absolute z-10 pointer-events-none;
              top: px-to-rem(2px);

              background-color: transparent;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#b1d249' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: px-to-rem(12px) px-to-rem(12px);
              content: '';
              left: px-to-rem(1px);

              @apply h-5 w-5 ;
            }
          }

          &:hover + span {

            &::before {
              @apply bg-white border-grey-400 shadow-md;
            }
          }
        }

        > span,
        input[type='radio'] + span {

          &::before {
            @apply block rounded-sm absolute left-0 top-0;
            @apply border-2 border-grey-300 bg-grey-100;
            @apply text-center overflow-hidden;

            content: '';
            height: $form-custom-size;
            line-height: $form-custom-size;
            width: $form-custom-size;
          }

          &::after,
          & + .input-icon {
            @apply hidden;
          }
        }
      }
    }

    &--checkbox {

      .input {
        @apply inline-flex;
      }

      .input__field {
        @apply w-auto;
        @apply mr-6;
      }
    }
  }

  &__button {
    @apply mt-4;
  }
}
