.list {

    $self: &;
  
    &--preset-7 {
      @apply font-2 leading-normal text-xs;
  
      @screen md {
        @apply text-sm;
      }
  
      li {
        @apply mb-1;
  
        &::before {
          @apply text-brand-1;
        }
      }
  
      &#{$self}--icon {
  
        li {
          padding-left: 1.5rem;
          &::before {
            @apply hidden;
          }
  
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1153_938)'%3E%3Cpath d='M0 0V19.22L2.89759 17.1562V1.95952L0 0Z' fill='%23ED2939'/%3E%3Cpath d='M4.16919 2.81421V16.2599L6.73325 14.4671V4.58612L4.16919 2.81421Z' fill='%23ED2939'/%3E%3Cpath d='M8.12988 5.58673V13.6333L10.8399 11.7155V7.48372L8.12988 5.58673Z' fill='%23ED2939'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1153_938'%3E%3Crect width='10.8399' height='19.22' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            margin-top: 0.45rem;
            margin-left: 0;
            @if $rtl {
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
            }
          }
         
        }
      }
      
      // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
    }
  }
  