.icon-text {
  $self: &;

  max-width: px-to-rem(240px);

  &__icon {
    @apply w-full pl-2 pr-1 mb-3;

    svg {
      @apply block m-auto w-full fill-current;

      max-height: px-to-rem(72px);
      max-width: px-to-rem(72px);
    }

    #{ $self }--preset-1 & {
      color: $brand-color-1;
    }

    #{ $self }--preset-2 & {
      color: $brand-color-2;
    }

    #{ $self }--preset-3 & {
      color: #43b02a;
    }

    #{ $self }--preset-4 & {
      color: #00a1de;
    }

    #{ $self }--preset-5 & {
      color: #af9800;
    }
  }

  &__content {
    @apply text-left;
  }

  &__title {
    @apply font-700 w-full text-basesm leading-snug mb-2;
  }

  &__text {
    @apply text-2xs w-full leading-snug;
  }
}

@import 'horizontal';
