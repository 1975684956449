/* purgecss start ignore */

.filters {
  $self: &;

  @apply top-0;
  transition: padding 200ms ease-in-out;

  @screen sm {
    @apply pt-24;
    margin: 0 auto;
  }

  &__header {
    @apply flex items-center;
    padding: 1rem 0 2rem;

    @screen sm {
      @apply hidden;
    }

    #{ $self }__close {
      @apply mr-3;
      width: 1.5rem;
      height: 1.5rem;

      .icon svg {
        top: 0;
        transform: none;
      }
    }

    #{ $self }__title {
      @apply text-xl font-700;
    }

    #{ $self }__reset {
      @apply ml-auto;
      color: $brand-color-1;
    }
  }

  .filters {
    padding-bottom: 3rem;
  }

  @screen sm {
    &--sticky {
      padding-top: px-to-rem(150px);
    }
  }

  &__group {
    @apply py-2;
  }

  &__content {
    @apply my-4 hidden;
    position: relative;

    &--visible {
      @apply block;
    }
  }

  &__title-bar,
  &__filter {
    @apply flex items-center fill-current;

    .icon {
      @apply w-5 h-5 inline-block;

      &--info-circle {
        @apply w-4 h-4;
      }
    }
  }

  &__title {
    @apply text-sm font-700 mr-2;

    @screen md {
      white-space: nowrap;
    }

    @screen 2xl {
      @apply text-base;
    }
  }

  &__toggle {
    @apply ml-auto flex items-center;

    &-checkbox {
      @apply absolute right-0 opacity-0;

      &:focus {
        ~.filters__toggle-visual {
          box-shadow: 0 0 3px 3px rgba($brand-color-2, 0.4);
        }
      }
    }

    &-visual {
      @apply flex items-center;
    }
  }

  &__filter {
    @apply flex items-center my-1 text-xs;

    @screen lg {
      @apply text-sm;
    }
  }

  &__text {
    @apply mr-2;
  }

  &__filter-checkbox {
    @apply absolute opacity-0;

    &:focus {

      ~.filters__filter-visual-checkbox,
      ~.filters__filter-visual-radio {
        box-shadow: 0 0 3px 3px rgba($brand-color-2, 0.4);
      }
    }

    &:checked {

      ~.filters__filter-visual-checkbox,
      ~.filters__filter-visual-radio {
        &::before {
          @apply block;
        }
      }
    }
  }

  &__filter-visual-checkbox,
  &__filter-visual-radio {
    @apply w-3 h-3 border-black border-solid border mr-3 relative;

    @if $rtl {
      @apply ml-3 mr-0;
    }

    &::before {
      @apply absolute left-50 top-50 w-2 h-2 hidden;

      content: "";
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &__filter-visual-checkbox,
  &__filter-visual-radio {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ED2939' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  #{ $self }__button-holder {
    position: sticky;
    bottom: 0;
    padding: 0.5rem 0.8rem;
    background: #ffffff;
    margin: auto -1rem 0;
    display: flex;
    justify-content: center;
    box-shadow: 10px -20px 40px rgba(0, 0, 0, 0.1);

    #{ $self }__button-results {
      @apply bg-brand-1 flex;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.75rem 1rem;
      color: #ffffff;
      width: 100%;
    }

    @screen sm {
      @apply hidden;
    }
  }

  .popover {
    @apply mr-2;

    .popover__content {
      left: -20px;
      transform: translateX(0) translateY(-100px);

      &::after {
        left: 30px;
      }
    }

    .popover__trigger:hover+.popover__content {
      transform: translateX(0) translateY(0);
    }
  }
}

/* purgecss end ignore */