.alert {
  @apply px-2 py-3 rounded-sm shadow-md text-left;
  @apply relative overflow-hidden;
  @apply border-2 border-grey-400;

  @extend .spacing;

  &::after {
    @apply block;
    @apply absolute left-0 right-0 top-0 bottom-0 -z-1;
    @apply h-full w-full;
    @apply bg-grey-400 opacity-10;

    content: '';
  }

  .title {
    @apply text-base mb-1;
  }

  &--success {
    @apply border-status-ok;

    &::after {
      @apply bg-status-ok opacity-25;
    }
  }

  &--info {
    @apply border-status-info;

    &::after {
      @apply bg-status-info opacity-25;
    }
  }

  &--error {
    @apply border-status-alert;

    .title {
      @apply text-status-alert;
    }

    &::after {
      @apply bg-status-alert opacity-10;
    }
  }
}
