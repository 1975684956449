
/* purgecss start ignore */

[data-language-switch-dropdown] {}

[data-language-switch-button] {
  @apply block ;

  &[aria-hidden='true'] {
    @apply hidden ;
    @apply pointer-events-none;
  }
}

@import 'default';

/* purgecss end ignore */
