html {
  @if $rtl {
    direction: rtl;
  }
}

body {
  @apply font-1 text-grey-800;

  @include mq(1920) {

    &.body {
      @apply bg-grey-100;
    }
  }
}

.site {
  @apply mx-auto min-h-full;

  max-width: 1920px;
  min-width: 320px;

  @include mq(1920) {
    @apply shadow-2xl bg-white;
  }

  /* purgecss start ignore */

  .show-menu & {
    @apply h-0 overflow-hidden;

    @screen sm {
      @apply h-auto;
    }
  }

  /* purgecss end ignore */
}
