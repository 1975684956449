.b-collection {
  $self: &;

  &--preset-2 {
    @apply flex flex-wrap;

    .item {
      @apply flex-initial w-full;

      @screen md {
        @apply flex-initial w-1/2;
      }

      @screen xl {
        @apply flex-initial w-1/3;
      }
    }
  }
}
