.button {
  @apply font-2 font-500 no-underline leading-loose rounded-sm;
  @apply relative overflow-hidden;
  @apply inline-flex items-center;

  +.button {
    @apply mb-1;
  }

  span {
    @apply relative z-10;
  }

  &::after {
    @apply absolute left-0 top-0 right-0 bottom-0 block z-0 bg-black opacity-0;

    content: '';
    transition: opacity 200ms ease-in-out;
  }

  &:hover {

    &::after {
      @apply opacity-10;
    }
  }

  &:focus {
    @apply outline-none;
  }

  &--preset-1 {
    @apply bg-brand-1 text-white;

    @if $theme == '4' {
      background-color: var(--theme-color-brand3);
    }

    &:hover {
      @apply text-white;
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }

  &--preset-2 {
    @apply bg-brand-2 text-white;

    &:hover {
      @apply text-white;
    }
  }

  &--preset-3 {
    @apply bg-brand-3 text-brand-2;

    @if $theme == '4' {
      background-color: var(--theme-color-brand1);
    }

    &:hover {
      @apply text-brand-2;
    }
  }

  &--preset-sub-1 {
    @apply bg-sub-1 text-sub-3;

    &:hover {
      @apply text-white;
    }
  }

  &--preset-sub-2 {
    @apply bg-sub-2 text-white;

    &:hover {
      @apply text-white;
    }
  }

  &--preset-sub-3 {
    @apply bg-sub-3 text-white;

    &:hover {
      @apply text-white;
    }
  }

  &--preset-sub-4 {
    @apply bg-sub-4 text-white;

    &:hover {
      @apply text-white;
    }
  }

  &--shopping-cart {
    padding: 0.5rem !important; 
    border: 2px solid rgba(0,0,0,var(--text-opacity));
    background: transparent;
    border-radius: 100%;
    display: inline-block;
    @apply mr-5;
    .icon svg {
      transform: none;
      width: 15px;   
      fill: rgba(0,0,0,var(--text-opacity));
    }
    &:hover, &:focus {
        border: 2px solid $brand-color-1;
        background: transparent;
        outline:none;
        .icon svg {          
          fill: $brand-color-1;
          
        }
        &:after {display: none;}
    }
  }
}