@use 'sass:math';

.popover {
  $arrow-height: 15px;
  $arrow-width: 30px;

  @apply relative;

  &__content {
    @apply bg-brand-2 text-white p-4 absolute left-50 shadow-md opacity-0 pointer-events-none z-40;

    bottom: calc(100% + #{$arrow-height});
    margin-top: -$arrow-height;
    min-width: 250px;
    transform: translateX(-50%) translateY(-100px);
    transition: opacity 200ms ease, transform 100ms ease;

    &::after {
      @apply w-0 h-0 border-solid border-brand-2 absolute left-50 top-100;

      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-width: $arrow-height #{math.div($arrow-width, 2)} 0 #{math.div($arrow-width, 2)};
      content: '';
      transform: translateX(-50%);
    }
  }

  &__trigger {
    @apply flex items-center;
  }

  &__trigger:hover + &__content {
    @apply opacity-100 pointer-events-auto;

    transform: translateX(-50%) translateY(0);
  }
}
