@mixin thAlternate() {

  th {
    @apply bg-transparent relative;
    @apply w-1 whitespace-no-wrap;

    &::after,
    &::before {
      @apply block absolute left-0 right-0 bottom-0 top-0 h-full w-full;
      @apply -z-2;

      content: '';
    }

    &::before {
      @apply -z-1;
      @apply bg-black -z-1 opacity-10;
    }
  }
}

table.table {
  @apply border border-grey-300;
  @apply w-full max-w-content;

  th {
    @extend .text--preset-body;

    @apply p-2 text-left;
    @apply bg-brand-1 text-white;
    @apply font-500;   

    &::after {
      @apply bg-brand-1;
    }
  }

  td {
    @extend .text--preset-small;

    @apply p-2 text-left;
  }

  &--preset-1 {

    td {
      @apply border border-grey-300;
    }

    tbody {

      th {
        @apply w-1 whitespace-no-wrap;
      }
    }

    thead + tbody {
      @include thAlternate();
    }
  }

  &--preset-2 {

    tbody tr {
      @apply relative;

      &:nth-child(odd) {

        td {
          @apply bg-grey-100;
        }

        @include thAlternate();
      }
    }
  }

  &--preset-3 {

    td {
      @apply border-r border-grey-300;

      &:nth-child(odd) {
        @apply bg-grey-100;
      }
    }

    tbody {

      th {
        @apply w-1 whitespace-no-wrap;
      }
    }

    thead + tbody {
      @include thAlternate();
    }
  }
}
