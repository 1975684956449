/* purgecss start ignore */

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='date'],
select,
textarea {
  @apply appearance-none w-full bg-no-repeat ;
  @apply h-12 py-3 px-3;
  @apply border bg-white border-brand-4;
  @apply relative z-0;

  background-position: 98% 50%;

  &:hover,
  &:focus {
    @apply outline-none border-grey-400 shadow-lg bg-white;
  }

  &::placeholder, option:disabled  {
    @apply text-grey-500 not-italic;
  }

  &.interacted {

    &:required {
      @apply pr-12;
    }

    &:required:invalid {
      @apply bg-white border-status-alert;
    }
  }
}

/* purgecss end ignore */

textarea {
  @apply leading-tight;
  @apply h-auto resize-y pt-2;

  background-position: 98% 10px;
  max-height: 600px;
  min-height: 200px;
}

select {
  @apply cursor-pointer;
  @apply text-grey-500 not-italic;
  &.interacted {
    color: rgba(75,75,75,var(--text-opacity));
  }
  option {
    color: rgba(75,75,75,var(--text-opacity));

    &:checked {
      background-color: var(--theme-color-brand4);
      color: white;
    }
  }
  /* stylelint-disable */
  _:-ms-fullscreen,
  :root  & {
    padding: 0 0 0 10px;
    @if $rtl {
      padding: 0 10px 0 10px;
     }
    &::-ms-expand {
      @apply hidden;
    }
  }
  /* stylelint-enable */
}
