.card {
  $self: &;

  &--preset-2 {
    @apply text-white;

    #{$self}__image {

      .figure {
        @apply block;
      }
    }

    #{$self}__body {
      @apply absolute left-0 bottom-0 right-0 p-4 z-20;
      @apply flex flex-wrap;
      @apply pointer-events-none;
      text-shadow: 0 1px 5px #4b4b4b;


      &::before {
        @apply absolute left-0 right-0 bottom-0 top-0 -mt-8 -z-1;

        background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
        content: '';
      }

      a {
        @apply pointer-events-auto;
      }
    }

    #{$self}__title {
      @apply w-full;

      @extend .spacing--preset-s;

      @if $rtl {
        @apply text-right #{!important};
      }

      h3 {
        @extend .title--preset-6;
      }
    }

    #{$self}__meta {
      @apply w-full order-first;

      @extend .spacing--preset-s;

      #{$self}__date {    

        @extend .text--preset-small;
        @extend .spacing--preset-s;
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 3px;
          + .icon {
            margin-left: 15px;
          }
        }
      }

      .button--tag {
        text-shadow: none;
      }
    }

    #{$self}__description {
      @apply hidden;

      @screen md {
        @apply block;
      }
    }
  }
}
