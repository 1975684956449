.job-openings {
  @apply my-8;

  @screen md {
    @apply mb-12;
  }

  &__item {
    @apply mb-4;

    @screen md {
      padding-left: calc(50% - #{px-to-rem(28px)});

      @apply mb-8;
    }
  }
}
