.icon {

  svg {
    @apply relative max-w-full max-h-full top-50;

    transform: translateY(-50%);
  }
}

button:focus {
  outline: none;
 
}