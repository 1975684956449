@import 'detail-header', 'preset-1', 'preset-2';

.band--header {

  .title--preset-1 {
    @apply text-4xl uppercase max-w-3xl;

    @screen md {
      @apply text-5xl mb-12;
    }

    @screen xl {
      @apply text-6xl mb-12;
    }
  }

  .list--preset-3 {
    @apply text-base;

    @include strongTextClass();

    li {
      @apply text-left;

      &::after {
        @apply w-6 h-6;
      }
    }
  }
}
