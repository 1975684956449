@import 'contact', 'preset-1', 'preset-2', 'preset-3', 'preset-4', 'preset-5', 'preset-6';

.card {
  $self: &;

  @apply relative mx-auto;

  &--preset-1,
  &--preset-2,
  &__container {
    @apply max-w-3xl;
    @apply border border-grey-200;

    &::after {
      @apply absolute block top-0 bottom-0 left-0 right-0 -z-1;
      @apply shadow-xl opacity-0;

      content: '';
      transition: opacity 150ms ease-in-out;
    }

    #{$self}__image {
      figure {
        @apply overflow-hidden;
      }

      img {
        @apply shadow-none rounded-none;
      }

      video {
        background-color: #000;
        display: block;
        object-fit: cover;
        width: 100%;
      }
    }

    &:hover {
      @apply border-grey-300;

      &::after {
        @apply opacity-100;
      }
      .card__image::after {
        width: 100%;
      }
    }

    #{$self}__content {
      @apply;
    }

    #{$self}__link {
      @apply w-full flex justify-end pt-4;

      a {
        @apply transition-all duration-200 ease-in-out;

        &:hover {}
      }

      svg {
        @apply w-8 h-8;
      }
    }
  }
}

.card__image[data-video-not-played] {
  @apply relative;

  &::before {
    @apply absolute block opacity-50 top-0 left-0 right-0 bottom-0 cursor-pointer transition-opacity duration-300;

    background: #000;
    content: '';
    z-index: 11;
  }

  &::after {
    @apply absolute block opacity-75 top-50 left-50 cursor-pointer transition-opacity duration-300;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zM40 256c0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216zm331.7-18l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM192 335.8V176.9c0-4.7 5.1-7.6 9.1-5.1l134.5 81.7c3.9 2.4 3.8 8.1-.1 10.3L201 341c-4 2.3-9-.6-9-5.2z'%3E%3C/path%3E%3C/svg%3E");
    content: '';
    height: 75px;
    transform: translate(-50%, -50%);
    width: 75px;
    z-index: 12;
    background-repeat: no-repeat; 
  }

  &:hover {

    &::after {
      @apply opacity-100;
    }

    &::before {
      @apply opacity-0;
    }
  }
}