/* purgecss start ignore */
$transitionSpeed: 300ms;

.expendable-content {
  $self: &;

  @apply relative;

  &__content {
    @apply duration-150;

    transition: max-height $transitionSpeed ease-in-out;

    &::after {
      @apply absolute left-0 right-0 bottom-0 pointer-events-none hidden transition-opacity duration-200;

      // TODO: The background gradient is not customizable and doesn't work with tailwind. We should find another solution for this
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 1) 0,
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      height: 150px;

      // Overwrites for the background-gradients per band preset

      .band--preset-6 & {
        background-image: linear-gradient(
          to top,
          rgba($brand-color-5, 1) 0,
          rgba($brand-color-5, 0) 100%
        );
      }
    }
  }

  &__toggle {
    @apply flex font-700 w-full text-brand-2 bg-white relative z-10 appearance-none transition-colors;

    transition-duration: $transitionSpeed;

    // This is hopefully temporary until I implement the keboard focus code in base

    &:focus {
      outline: none;
    }

    // Overwrites for the background-gradients per band preset

    .band--preset-6 & {
      @apply bg-brand-5;
    }

    &:hover {
      @apply text-brand-1 underline;
    }

    &-text {
      @apply flex items-center font-700 cursor-pointer py-4 mx-auto;

      .icon {
        @apply w-6 h-6 inline-block fill-current;
      }
    }

    &[aria-expanded="false"] {
      .icon {
        transform-origin: 50% 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &[aria-expanded="true"] {
      .icon {
        transform: rotate(-180deg);
      }
    }
  }

  // States

  [aria-hidden="true"] {
    @apply hidden;
  }

  &[data-is-expanded="false"] {
    #{$self}__content {
      @apply overflow-hidden relative;

      &::after {
        @apply block opacity-100;
      }
    }
  }

  &[data-is-expanded="true"] {
    #{$self}__content {
      @apply overflow-hidden;

      &::after {
        @apply opacity-0;
      }
    }
  }

  &[data-is-animating] {
    #{$self}__content {
      @apply relative #{ !important };
    }
  }
}
/* purgecss end ignore */
