.timeline {
  $self: &;

  @apply relative my-0;

  &__item {
    @apply relative;
    @apply flex;
    @apply py-4;

    margin-bottom: px-to-rem(25px);

    &:last-child {
      @apply pb-0;

      margin-bottom: px-to-rem(0);
    }

    @screen lg {
      margin-bottom: px-to-rem(45px);

      &:last-child {
        margin-bottom: px-to-rem(15px);
      }
    }
  }

  &__counter-container {
    @screen lg {
      @apply w-1/2;
    }

    &::before {
      @apply absolute bottom-0;
      @apply block bg-black pointer-events-none;

      content: '';
      left: px-to-rem(22px);
      top: px-to-rem(92px);
      width: px-to-rem(10px);

      @screen lg {
        @apply left-50;

        top: px-to-rem(130px);
        transform: translateX(-50%);
      }
    }

    &::after {
      @apply absolute;
      @apply block pointer-events-none;

      border-bottom: 20px solid black;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      content: '';
      height: 0;
      left: px-to-rem(13px);
      top: px-to-rem(76px);
      width: 0;
      width: px-to-rem(10px);

      @screen lg {
        @apply left-50;

        top: px-to-rem(112px);
        transform: translateX(-50%);
      }
    }
  }

  &__counter {
    @apply block absolute top-0 left-0;
    @apply bg-brand-1 text-white text-center;
    @apply rounded-full;

    font-size: px-to-rem(22px);
    height: px-to-rem(54px);
    line-height: px-to-rem(54px);
    min-width: px-to-rem(54px);

    @screen lg {
      @apply left-50;

      font-size: px-to-rem(32px);
      height: px-to-rem(72px);
      line-height: px-to-rem(72);
      transform: translateX(-50%);
      width: px-to-rem(72px);
    }
  }

  &__content-container {
    padding-left: px-to-rem(70px);

    @screen lg {
      @apply w-1/2;

      padding-left: px-to-rem(60px);
    }
  }

  &__content-block {
    @screen lg {
      max-width: px-to-rem(300px);
    }
  }

  &__title {
    @apply font-700;
    @apply mb-2;

    font-size: px-to-rem(22px);
    line-height: px-to-rem(26px);

    @screen lg {
      font-size: px-to-rem(28px);
      line-height: px-to-rem(32);
    }
  }

  &__content {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);

    &.rte {

    }
  }

  @screen lg {
    @apply mt-12 mb-0;

    &::before {
      @apply absolute;
      @apply block bg-black;

      content: '';
      height: 50px;
      left: px-to-rem(22px);
      top: px-to-rem(-100px);
      width: px-to-rem(10px);

      @screen lg {
        @apply left-50;

        height: 92px;
        top: px-to-rem(-142px);
        transform: translateX(-50%);
      }
    }

    &__item--left {
      @apply text-right;

      #{$self}__counter-container {
        @apply order-2;
      }

      #{$self}__content-container {
        @apply order-1 pl-0  flex justify-end;

        padding-right: px-to-rem(60px);
      }
    }

    &__item--right {
      @apply text-left;

      #{$self}__counter-container {
        @apply order-1;
      }

      #{$self}__content-container {
        @apply order-2 pr-0;

        padding-left: px-to-rem(60px);
      }
    }
  }
}
