.b-grid {

  &--preset-8 {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full;

      @screen sm {
        @apply flex-initial w-1/2 ;

        order: 2;
      }

      @screen md {
        @apply w-2/3;
      }

      @screen lg {
        @apply w-3/4;
      }

      &:nth-child(even) {
        @apply hidden;

        @screen sm {
          order: 1;

          @apply block;
        }

        @screen md {
          @apply w-1/3;
        }

        @screen lg {
          @apply w-1/4;
        }
      }
    }
  }
}
