.alt-content {

  // BODYTEXT

  .text {

    &--preset-body,
    &--preset-intro,
    &--preset-small,
    &--preset-caption,
    &--preset-quote {
      @apply text-white;
    }
  }

  .title {

    &--preset-1,
    &--preset-2,
    &--preset-3,
    &--preset-4,
    &--preset-5,
    &--preset-6 {
      @apply text-white;
    }
  }

  // LINKS

  .link {

    &--preset-1,
    &--preset-2,
    &--preset-title {
      @apply text-white;

      &:hover {
        @apply text-white;
      }
    }
  }

  // BUTTONS

  .button--preset-1 {
    @apply bg-brand-2;
  }

  // LISTS

  .list--preset-1,
  .list--preset-2,
  .list--preset-3 {

    li {
      @apply text-white;

      &::before {
        @apply text-brand-2;
      }
    }

    &.list--icon {

      li {

        &::before {
          @apply hidden;
        }
      }
    }
  }
}
