/* purgecss start ignore */
.band {
	$self: &;

	@apply mx-auto p-4;
	@apply max-w-full overflow-hidden;
	@apply relative;
	z-index: 1;

	&--header {
		@apply z-10;
	}

	.band__content {
		@apply max-w-3xl mx-auto px-2;
		@apply relative z-10;

		@screen lg {
			@apply max-w-content px-4;
		}
	}

	.band__image {
		@apply absolute z-0 left-0 right-0 top-0 bottom-0 block;
		@apply bg-cover #{!important};
		@apply bg-no-repeat bg-center;

		img {
			@apply h-full w-full;

			object-fit: cover;
		}
	}

	.band {
		@apply p-0;

		.band__content {
			@apply px-0;
		}
	}

	&--preset-1,
	&--preset-2,
	&--preset-3,
	&--preset-6,
	&--watermark,
	&--full-width {
		@apply py-8;

		@screen md {
			@apply py-12;
		}

		@screen lg {
			@apply py-16;
		}
	}

	&--preset-1 {
		.rte {
			text-align: left;
			margin: 0;
			max-width: 100%;
		}

		&.text-center {
			.rte {
				text-align: center;
			}
		}
	}

	&--preset-2 {
		@apply bg-grey-200;
	}

	&--preset-3 {
		@apply bg-brand-1;
	}

	&--preset-4 {
		@apply py-6;
		// @apply bg-black;
	}

	&--preset-6 {
		@apply bg-brand-5;
	}

	// BACKGROUND

	&--preset-5 {
		.band__image {
			&::after {
				@apply absolute left-0 top-0 right-0 bottom-0 z-0;
				@apply bg-black;

				content: '';
			}
		}
	}

	// Custom

	&--overview {
		@apply p-0 overflow-visible z-20;

		.band__content {
			@apply ml-0 pl-0 max-w-site;

			@if $rtl {
				text-align: right;
				margin-left: auto;
				margin-right: 0;
			}

			@include mq(1500) {
				@apply px-0;

				width: calc(1500px + ((100vw - 1500px) / 2));
			}

			@include mq(1920) {
				@apply pr-4;

				width: calc(1500px + ((1920px - 1500px) / 2));
			}
		}
	}

	&--centered {
		@apply text-center;
	}

	&--usp {
		@apply z-10 py-0 overflow-visible;
	}

	&--has-watermark {
		overflow: initial;

		&::after {
			@apply block absolute top-0 right-0 -z-1 -mt-10;
			@apply h-auto w-full;
			@apply bg-no-repeat pointer-events-none bg-contain bg-right-top;

			background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 93 48' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M.02.095h39.22v43.226H.02z'%3E%3C/path%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M48.48 8.254c.28-.436.548-.86.812-1.267h-4.555l-1.645-2.468h7.772c.296-.465.57-.892.809-1.27h-9.207L41.112 1.28h-2.845c-.082.12-29.4 42.222-29.705 42.665h27.192c-2.103-2.134-3.266-4.84-3.266-7.661 0-1.794.445-3.483 1.317-5.024 0 0 7.577-11.88 13.195-20.682l-1.5-2.325h2.98z' fill='%23f8f8f8'%3E%3C/path%3E%3Cg transform='translate(8 .906)'%3E%3Cmask id='b' fill='%23f8f8f8'%3E%3Cuse xlink:href='%23a'%3E%3C/use%3E%3C/mask%3E%3Cpath d='M24.769 35.379c0-1.743.432-3.388 1.28-4.886.006-.005 7.517-11.783 13.19-20.676h-.15l-.006-.013-.085-.131-13.193 20.68a10.073 10.073 0 00-1.317 5.026c0 2.82 1.162 5.526 3.265 7.662H.562C.867 42.598 30.185.497 30.267.376h2.844l-.097-.14-.096-.141h-2.795L.02 43.32h28.412l-.253-.245c-2.201-2.116-3.41-4.847-3.41-7.697' fill='%23f8f8f8' mask='url(%23b)'%3E%3C/path%3E%3C/g%3E%3Cpath fill='%23f8f8f8' d='M63.234 10.405H47.267l-1.178-1.83h15.804zm2.682 3.736H49.742l-1.178-1.867h16.01zm2.639 3.658H52.183l-1.22-1.827h16.25zm2.722 3.82H54.7l-1.257-1.827h16.492zm2.519 3.615h-16.82l-1.217-1.87h16.697zm2.723 3.738H59.496l-1.26-1.87h16.942zM79.2 32.67H61.61c-.206-.487-.405-1.057-.691-1.504l-.203-.323H77.86l1.34 1.827zm2.641 3.696H62.216v-.284c0-.529-.079-1.057-.16-1.543H80.5l1.342 1.827zm2.639 3.778H61.366a8.993 8.993 0 00.568-1.867h21.21l1.338 1.867zm2.724 3.739h-28.4c.567-.57 1.139-1.178 1.546-1.87h25.474l1.38 1.87z'%3E%3C/path%3E%3Cpath fill='%23f8f8f8' d='M41.375 1.1h15.396l1.343 1.827h-15.48zm19.259 5.566H44.911l-1.22-1.83h15.601z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
			content: '';
			padding-bottom: 50%;
		}

		.band--breadcrumb + & {
			&::after {
				@apply mt-0;
			}
		}
	}

	&--news-detail + .band--preset-1 {
		@apply pt-0 -mt-8;

		@screen md {
			@apply -mt-12;
		}

		@screen lg {
			@apply -mt-16;
		}
	}

	&--preset-1 + .band--preset-1 {
		@apply pt-2;

		@screen md {
			@apply pt-3;
		}

		@screen lg {
			@apply pt-4;
		}
	}

	&--potato-value-chain {
		@apply my-0 py-10 bg-no-repeat bg-cover bg-center overflow-visible z-auto;

		@screen lg {
			padding-bottom: 0;
			padding-top: px-to-rem(45px);
		}

		.band__content {
			@apply z-auto;
		}

		&#{$self}--potato-value-chain-first {
			.timeline {
				&::before {
					@apply hidden;
				}
			}
		}

		&#{$self}--potato-value-chain-last {
			.timeline {
				&__item:last-child {
					.timeline__counter-container {
						&::before,
						&::after {
							@apply hidden;
						}
					}
				}
			}
		}
	}

	&--full-width {
		background-size: cover;
		background-repeat: no-repeat;

		&:before {
			content: '' !important;
			position: absolute;
			background: rgba(0, 0, 0, 0.2);
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.band__content {
			p {
				text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
			}
		}
	}

	&--header-slider.band--header {
		--swiper-theme-color: #fff;
		@apply p-0;
		min-height: auto;

		.swiper-pagination {
			--swiper-pagination-bullet-height: 1.25rem;
			--swiper-pagination-bullet-width: 1.25rem;
			--swiper-pagination-bottom: 3rem;
			--swiper-pagination-color: var(--theme-color-brand1);
			z-index: 100;

			&-bullet {
				border: 4px solid white;
				background: var(--swiper-pagination-bullet-inactive-color, #fff);
				opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
				&-active {
					background: var(--swiper-pagination-color, var(--swiper-theme-color));
					opacity: var(--swiper-pagination-bullet-opacity, 1);
				}
			}
		}

		.swiper-button-next:after,
		.swiper-button-prev:after {
			text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
		}

		.swiper-button-disabled {
			opacity: 0.3;
		}

		.band__content {
			@apply absolute text-left text-white z-20;
			@apply self-end;
			@apply mx-16 px-6;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			top: 50%;
			transform: translateY(-50%);

			@screen md {
				@apply self-center px-4;
			}

			.title {
				&--preset-1 {
					@apply mb-0;
					text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);

					@media screen and (max-width: 480px) {
						font-size: 1.45rem;
					}
				}
			}
		}
	}
}

/* purgecss end ignore */
