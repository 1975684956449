@use 'sass:map';
@use 'sass:math';

:root {
  --gutter-width: 30px;
  --half-gutter-width: calc(var(--gutter-width) * 0.5);
}

@mixin mq($point, $query1: min, $query2: width) {
  @if $query1 == 'max' {
    @media screen and (#{$query1}-#{$query2}: #{$point - 1}px) {
      @content;
    }
  }

  @else {
    @media screen and (#{$query1}-#{$query2}: $point+px) {
      @content;
    }
  }
}

/*  Functions */
$base-font-size: 16; // Based off the preflight/normalize setting, assuming no changes.
$base-config: (
  'breakpoints': (
    'sm': '640px',
    'md': '768px',
    'lg': '1024px',
    'xl': '1280px',
    '2xl': '1400px'
  )
) !default;

// Strip unit values from input and return the integer
@function strip-units($val) {
  @return math.div($val, $val * 0 + 1);
}

// Takes a pixel value and returns the rem value
@function px-to-rem($new-px) {
  @return math.div(strip-units($new-px), $base-font-size) * 1rem;
}



@mixin generate-flex-columns($start: 2, $end: 12) {
  $breakpoints: map.get($base-config, 'breakpoints');

  @for $i from $start through $end {
    .col-md-#{$i} {
      padding-left: var(--half-gutter-width);
      padding-right: var(--half-gutter-width);
      flex: 0 0 100%;
      max-width: 100%;
     

      @media (min-width: map.get($breakpoints, 'sm')) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @media (min-width: map.get($breakpoints, 'md')) {
        flex: 0 0 50%;
      }

      @media (min-width: map.get($breakpoints, 'lg')) {
        flex: 0 0 auto;
        width: percentage(math.div($i, 12));
        max-width: unset;
      }

      @each $breakpoint, $min-width in $breakpoints {
        @media (min-width: $min-width) {

          // Adjust the style for col-12
          &.col-#{$breakpoint}-12 {
            flex: 0 0 auto;
            max-width: unset;
            width: 100%;
          }
        }
      }
    }
  }
}
