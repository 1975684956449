/* purgecss start ignore */

.facebook-embed {
  .fb-page {
    text-align: center;
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }

  &--left {
    .fb-page {
      text-align: left;
    }
  }

  &--right {
    .fb-page {
      text-align: right;
    }
  }
}

/* purgecss endt ignore */
