.b-grid--cta-text {
  @apply flex-wrap justify-start items-center;

  .col {
    @apply w-full;
  }

  @screen md {
    @apply flex-no-wrap;

    .col {
      @apply w-auto;

      &--cta {
        @apply flex-shrink-0;
      }

      &--text {
        @apply flex-auto;
      }
    }
  }
}
