.form {
  $self: &;

  &--preset-1 {
    @apply max-w-content-small mx-auto;

    #{$self}__fields {
      @apply mb-8;
    }
  }
}
