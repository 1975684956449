/* purgecss start ignore */
.body--login {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(78, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url(../../assets/images/library/login-telerportaal-mobile.jpg);

  @screen sm {
    background-image: linear-gradient(rgba(78, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/library/login-telerportaal-tablet.jpg);
  }

  @screen lg {
    background-image: linear-gradient(rgba(78, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/library/login-telerportaal-desktop.jpg);
  }

  background-size: cover;
  background-position: center;

  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);

    @screen sm {
      width: 100%;
    }
  }

  .band--breadcrumb {
    position: fixed;
    bottom: 0px;

    .menu--preset-3 .menu__item {
      &:after,
      span {
        color: rgba(255, 255, 255, var(--text-opacity));
      }

      a {
        --text-opacity: 1;
        color: rgba(255, 255, 255, var(--text-opacity));

        &:hover,
        &:focus {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}

.form {
  $self: &;
  background-color: #fff;

  &--preset-3 {
    @apply max-w-content-small mx-auto relative;
    max-width: 440px;
    padding: 2rem;
    .input {
      @apply mb-0;
    }
    input {
      @apply pr-16;
      @apply mb-4;
    }

    button {
      @apply px-3;
      @apply mb-4;
    }

    #{$self}__footer {
      font-size: small;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .col:first-child {
        @apply mb-2;
      }

      @screen md {
        flex-direction: row;
      }
    }
  }
}
/* purgecss end ignore */
