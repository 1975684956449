$arrow-width: 8px;

/* purgecss start ignore */

.languageswitch {
  $self: &;

  @apply relative;

  &__button {
    @apply relative inline-flex content-center text-black;
    @apply py-1;

    padding-right: px-to-rem(($arrow-width * 2));

    &:hover {
      text-decoration: underline;
    }

    &::before {

    }

    &:focus {
      outline: none;
    }

    .col--logo & {
      @apply pr-0 #{!important};
      transform: translateY(-12px);

      &::before {
        @apply block top-100 right-0 left-0 w-full h-5 leading-none border-0 #{!important};
        content: attr(lang) !important; /* stylelint-disable-line declaration-no-important */
        transform: none !important; /* stylelint-disable-line declaration-no-important */
        margin: 0 0 0;
        @apply uppercase text-xs text-center;
      }
    }
  }

  &__button-text{
    .col--logo & {
      @apply hidden;
    }
  }

  &__icon {
    @apply inline-block mr-2 w-5 h-5 fill-current;

    transform: translateY(px-to-rem(4px));

    svg {
      @apply fill-current;
    }

    .col--logo & {
      @apply w-6 h-6 mr-0 mb-1 block;

      svg {
        @apply block;
      }
    }
  }

  &__dropdown {
    @apply absolute block top-100 z-20;
    @apply rounded-sm block bg-white shadow-xl overflow-hidden;
    @apply pointer-events-auto;
    @apply opacity-100;
    @apply transform translate-y-1;

    max-width: px-to-rem(320px);
    min-width: px-to-rem(240px);
    transition: all 120ms ease-in-out;

    .col--logo & {
      @apply top-100 bottom-auto mt-1 left-auto right-0 shadow-lg border-2 border-grey-200;

      max-width: px-to-rem(200px);
      min-width: px-to-rem(150px);
    }

    @include mq(1024, max) {
      @apply bottom-100;

      top: auto;
    }

    &[aria-hidden='true'] {
      @apply transform translate-y-3;
      @apply opacity-0 pointer-events-none;

      visibility: hidden;
    }
  }

  &__list {
    @apply w-full;
  }

  &__item {}

  &__link {
    @apply block px-2 py-1;
    @apply transition-colors duration-100 ease-in-out;

    &:focus:not([aria-selected='true']),
    &:hover {
      @apply bg-grey-200;
    }

    &[aria-selected='true'] {
      @apply bg-brand-1;
      @apply text-white;
    }

    .col--logo & {
      @apply text-right;
    }
  }

  &__link-text {}

  [aria-expanded] {

    &::before {
      @apply block;
      @apply absolute top-50;
      @apply transition-all duration-100 ease-in-out;

      border-color: #000;
      border-radius: 0 1px 0 0;
      border-width: 2px 2px 0 0;
      content: '';
      height: px-to-rem($arrow-width);
      right: 0;
      transform: rotate(135deg) translateY(50%);
      transform-origin: 66.66% 33.33%;
      width: px-to-rem($arrow-width);

      .col--logo & {
        @apply hidden;
      }
    }
  }

  [aria-expanded='true'] {

    &::before {
      transform: rotate(-45deg) translateY(-50%);
    }
  }
}

/* purgecss end ignore */
