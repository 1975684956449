$slideSpacing: 2rem;

/* purgecss start ignore */

.slider {
  $self: &;

  &--preset-1 {
    width: calc(100% + #{$slideSpacing});

    .slider-content {
      @apply mb-4;

      margin-right: $slideSpacing;

      @screen sm {
        @apply mr-40;
      }
    }

    .slide {
      margin-right: $slideSpacing;
    }

    .tns-nav {
      margin-right: $slideSpacing;
    }

    .tns-controls {
      @apply absolute pb-4;
      right: 1.5rem;
      top: 0.5rem;
      transform: translateY(-100%);

      @screen sm {
        margin-right: $slideSpacing;
        @apply right-0 top-0;
      }

      &__button {
        @apply inline-block relative ml-2;
        @apply bg-white shadow-lg border border-grey-100;
        @apply h-10 w-10 rounded-full;

        @screen lg {
          @apply h-12 w-12;
        }

        .icon {
          @apply absolute left-50 top-50;
          @apply block h-4 w-4;

          transform: translate(-50%, -50%);
        }

        &:hover {
          @apply shadow-xl;

          .icon {
            @apply text-brand-1;
          }
        }
      }
    }
  }
}

/* purgecss end ignore */
