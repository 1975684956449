.b-collection {
  @apply mx-auto mb-8 max-w-content-large;

  .band__content & {
    @apply -mx-4;
  }

  $self: &;

  .item {
    @apply px-4 py-2;

    @extend .spacing;
  }
}

@import 'preset-1', 'preset-2', 'preset-3', 'preset-4',  'preset-5';

