/* purgecss start ignore */
:root {
	--theme-color-brand1-hover: #cd1120;
}
@font-face {
	font-family: 'Agrico Tuberosum Extended';
	font-style: normal;
	font-weight: normal;
	src:
		url('../fonts/agrico_tuberosum_extended-2-webfont.woff2') format('woff2'),
		url('../fonts/agrico_tuberosum_extended-2-webfont.woff') format('woff');
}

.detail-header {
	background-size: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.7rem;
	min-height: px-to-rem(375px);
	@media screen and (min-width: 1650px) {
		background-size: 100%;
		padding: 0;
	}
	.band__image {
		max-height: px-to-rem(375px);
	}
	.band__content {
		background: #fff;
		padding: 1rem;
		box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.15);
		width: 100%;
		margin-bottom: 2rem;
		@media screen and (min-width: 1650px) {
			margin: 2rem -3rem 3rem;
			padding: 1rem 3rem;
			max-width: calc(1500px + 6rem);
		}
	}
	&-top {
		@apply flex  items-center justify-between;
	}

	&__segments {
		@apply hidden;

		@screen sm {
			@apply flex;
			position: absolute;
			top: 0.8rem;
			right: 0;
			@if $rtl {
				right: auto !important;
				@media screen and (min-width: 1650px) {
					left: 2rem !important;
				}
			}
			@media screen and (min-width: 1650px) {
				right: 2rem;
			}
			> div {
				width: 5rem;
			}
		}

		&.is-mobile {
			@apply flex flex-col;

			@screen sm {
				@apply hidden;
			}
		}
		&-title {
			@apply text-xs font-700;
		}

		&-icons {
			@apply flex mt-3 mb-3;
			.icon {
				margin-left: 0;
				margin-right: 1rem;
			}
		}
		.icon {
			display: flex;
			justify-content: center;
			min-height: 2.125rem;

			svg {
				max-width: 2.3rem;
			}
		}
	}

	&__columns {
		@apply flex flex-col mt-8;

		@screen md {
			@apply flex-row;

			> * {
				@apply w-1/2;
			}
		}
	}

	&__info {
		@apply order-last;

		@screen md {
			@apply pr-8 order-none;
		}

		@screen lg {
			min-height: 29vw;
		}

		@screen 2xl {
			min-height: 410px;
		}
	}

	&__unavailable {
		@apply block bg-brand-1 px-4 py-1 text-white font-700 mb-4;

		em {
			@apply not-italic underline;
		}

		.icon {
			@apply inline-flex items-center fill-current w-4 h-5 float-right;

			> svg {
				@apply w-full;
			}
		}
	}

	&__description {
		//@apply mb-8;
	}

	.title.title--preset-1 {
		@apply pb-4 order-first text-6xl;
		font-family: 'Agrico Tuberosum Extended', 'Ubuntu', 'Helvetica', 'Arial',
			sans-serif;
		transform: rotate(-1.5deg);
		color: var(--theme-color-brand1);
		font-weight: normal;
		margin-bottom: 0.5rem;
		text-transform: none;
		&::after {
			content: '.';
		}

		@if $rtl {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
		}

		@screen md {
			@apply order-none text-7xl;
		}
	}

	&__subtitle {
		@apply mb-2 font-700;
		line-height: 1.2;
		font-size: 1.5rem;
		&-sub {
			font-weight: bold;
			a {
				text-decoration: underline;
			}
		}
	}
	p {
		line-height: 2;
		font-family: verdana;
	}

	.list.list--icon {
		font-size: inherit;
	}
	&__markets {
		@apply mb-8;
	}

	&__downloads {
		@apply mb-6 mt-6;
		@screen sm {
			@apply mt-0;
		}
	}

	&__slider {
		@apply relative flex items-start justify-center pb-5;
		margin-top: 1.5rem;
		@screen sm {
			@apply p-6;
		}
		.tns-nav {
			height: 26px;
		}

		> div:first-of-type {
			padding: 0.5rem 0;
			@apply z-10;
			@screen sm {
				padding: 0.5rem 1.5rem;
			}
		}
	}

	&__overlay {
		max-width: px-to-rem(90px);
	}

	&__read-more {
		&--inner {
			@apply flex items-center justify-center mt-3 mb-3;
		}

		&--link {
			@apply flex items-center font-700;
		}
		.icon {
			@apply inline-block mr-2;
			@if $rtl {
				@apply inline-block mr-0 ml-2;
			}
			background-color: var(--theme-color-brand1);
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem;
			&:hover,
			&:focus {
				background-color: var(--theme-color-brand1-hover);
			}
			svg {
				height: 1rem;
				width: 1rem;
				fill: #fff;
				transform: none;
				top: auto;
			}
		}
	}
}

.markets {
	&__market {
		@apply mb-6;
	}

	@screen lg {
		&__markets {
			@apply flex flex-wrap items-start justify-start;
		}

		&__market {
			@apply pr-4 w-1/2;
		}
	}
}

.downloads {
	flex-direction: column;
	&__download {
		@apply flex;
		padding: 0.25rem;
		font-family: Verdana, Geneva, Tahoma, sans-serif;
		@screen md {
			padding: 0.25rem 0.25rem 0.25rem 2rem;
		}
		@screen lg {
			@apply mb-0;
		}

		&:hover {
			@apply text-brand-1;
		}

		.icon {
			@apply inline-block w-4 h-5 text-brand-1 mr-2;
			min-width: 1rem;
			@if $rtl {
				@apply inline-block mr-0 ml-2;
			}
		}
	}

	.button {
    margin-top: .5rem;
		@screen md {
			margin: 0.5rem 0 0 2rem;
		}
	}
}

/* purgecss end ignore */
