.menu {

  $self: &;

  &--preset-3 {
    @apply flex flex-wrap;

    #{$self}__item {
      @apply whitespace-no-wrap text-2xs;

      &:not(.menu__item--selected)::after {
        @apply mx-1;
        content: '|';
        color: inherit;
      }

      a {
        @extend .link--preset-menu;
        @apply text-grey-800;
      }

      &--selected {
 
        span {
        }
      }
    }
  }
}
