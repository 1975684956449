$mediaRounded: false;
$mediaShadow: true;

@mixin mediaPresets() {
  @if $mediaRounded == true {
    @apply rounded;
  }

  @if $mediaShadow == true {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .3), 0 4px 6px -2px rgba(0, 0, 0, .05);
  }
}

@mixin strongTextClass() {
  @apply font-700;
}

@import 'alerts/', 'bands/', 'buttons/', 'collections/', 'formfields/', 'grids/', 'holder', 'images/', 'potatoicon/', 'lightbox/', 'lists/', 'maps-tooltip', 'spacing', 'social', 'tables/', 'toggle/', 'typography/', 'video/';
