/* purgecss start ignore */

.tns-outer {
  @apply flex relative flex-col-reverse;
  // @apply overflow-hidden;

  .body--IE11 & {
    @apply block;
  }

  [hidden] {
    @apply hidden #{!important};
  }

  [aria-controls],
  [data-action] {
    cursor: pointer;
  }

  &.ms-touch {
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    // -ms-scroll-chaining: none;
    scroll-snap-points-x: snapinterval(0%, 100%);
    scroll-snap-type: mandatory;
  }
}

.tns-slider {
  transition: all 0s;

  > .tns-item {
    box-sizing: border-box;
  }
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;

  > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
}

.tns-horizontal.tns-no-subpixel {

  &::after {
    clear: both;
    content: '';
    display: table;
  }

  > .tns-item {
    float: left;
    margin-right: -100%;
  }
}

.tns-no-calc {
  left: 0;
  position: relative;
}

.tns-gallery {
  left: 0;
  min-height: 1px;
  position: relative;

  > .tns-item {
    left: -100%;
    position: absolute;
    transition: transform 0s, opacity 0s;
  }

  > .tns-moving {
    transition: all .25s;
  }
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;

  &.loaded {
    opacity: 1;
  }
}

.tns-ah {
  transition: height 0s;
}

.tns-ovh {
  // overflow: hidden;
}

.tns-hdx {
  overflow-x: hidden;
}

.tns-hdy {
  overflow-y: hidden;
}

.tns-outer > button,
.tns-visually-hidden {
  @apply hidden;

  left: -10000em;
  position: absolute !important; /* stylelint-disable-line declaration-no-important */
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  z-index: -1;
}

.tns-nav {
  position: relative;
  text-align: center;
}

/* purgecss end ignore */
