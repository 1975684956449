/* purgecss start ignore */

.slider {
  $self: &;

  &--preset-4,
  &--preset-5 {
    @apply w-full overflow-hidden relative pb-10;

    .slider-content {
      @apply text-center mb-8;

      @screen sm {
        @apply pt-4 mb-12;
      }
    }

    .slider {
      @apply block #{!important};
    }

    .tns-ovh {
      @apply overflow-hidden #{!important};
    }

    .slide {
      @apply opacity-0 px-12 text-center;

      @screen lg {

        .card__body {
          @apply opacity-0;

          transform: translateY(-25%);
          transition: opacity 200ms 300ms, transform 1s;
        }
      }

      &.tns-slide-active {
        @apply opacity-100;

        @screen lg {

          .card__body {
            @apply opacity-100;

            transform: translateY(-50%);
          }
        }
      }
    }

    .tns-controls {
      @apply absolute left-0 right-0 top-50 z-50;

      transform: translateY(-50%);

      &__button {
        @apply border-0 shadow-none m-0;

        background: none;

        &:hover {
          @apply shadow-none;
        }
      }

      button {
        @apply absolute top-50;

        transform: translateY(-50%);

        &[data-controls='prev'] {
          @apply left-0;
        }

        &[data-controls='next'] {
          @apply right-0;
        }
      }
    }

    .tns-nav {
      @apply relative z-20 top-100 left-50 p-0 mt-2;
      @apply transform -translate-x-1/2;

      button {
        @apply bg-grey-300;

        &:hover {
          @apply bg-brand-2;
        }

        &.tns-nav-active {
          @apply bg-brand-2;
        }
      }
    }

    .b-grid & {
      @apply pb-0;

      .slide {
        @apply px-0;

        .figure {
          @apply block;
        }
      }

      .tns-controls {
        @apply w-full pointer-events-none;

        button {
          @apply relative pointer-events-auto;
          @apply h-8 w-8 m-2 rounded-full bg-white;

          // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .3), 0 4px 6px -2px rgba(0, 0, 0, .05);
          transform: translateY(0);

          &[data-controls='next'] {
            @apply float-right;
          }

          span {
            @apply opacity-50;
          }

          &:hover {

            span {
              @apply opacity-100;
            }
          }
        }

        &__button {
          @apply h-6 w-6 m-1;
        }
      }

      .tns-nav {
        @apply absolute bottom-auto top-100 z-50 left-50 p-1;
        @apply m-2;
        @apply transform -translate-x-1/2 -translate-y-12;

        button {
          @apply border-2 border-white bg-white opacity-100;

          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .3), 0 4px 6px -2px rgba(0, 0, 0, .05);

          &.tns-nav-active {
            @apply bg-brand-2;
          }
        }
      }
    }
  }
}

/* purgecss end ignore */
