.menu {
  $self: &;

  &--topbar {
    @apply flex items-center justify-end text-grey-900;

    @screen md {
      @apply relative z-10;
    }

    #{$self}__item {
      @apply ml-6 relative;

      &:last-child {
        @apply mr-6;
      }

      &--language,
      &--search {

        > .menu__link:hover,
        > .flex:hover {

          .form {
            @apply opacity-100 pointer-events-auto;

            transform: translateY(0);
          }
        }

        &:hover,
        &:focus-within {

          .form {
            @apply opacity-100 pointer-events-auto;

            transform: translateY(0);
          }
        }
      }

      @include mq(1024, max) {

        &--language {
          @apply order-last;

          .icon {
            @apply hidden;
          }
        }

        &--search {
          @apply order-last;

          .menu__label {
            @apply hidden;
          }

          .form {
            @apply rounded-full overflow-hidden mr-0 w-full;

            input {
              @apply border-0 py-2 bg-grey-100 h-auto;
            }

            button {
              @apply py-0;
            }

            .form__actions button::before {
              @apply w-5 h-5;
            }
          }
        }
      }
    }

    #{$self}__link {
      @apply flex items-center text-grey-900 py-1;

      &:hover {
        @apply text-brand-2;
      }

      &--button {
        @apply bg-brand-1 text-white py-1 px-4;

        &:hover {
          @apply bg-brand-2 text-white;
        }
      }
    }

    .icon {
      @apply inline-block w-4 h-4 mr-2;

      &--chevron-down {
        @apply w-3 h-3 mr-0;
      }
    }

    .form {
      @include mq(1024) {
        @apply absolute right-0 top-100 py-2 px-4 -mr-4 bg-white shadow-xl rounded-sm pointer-events-none opacity-0;

        min-width: 400px;
        transform: translateY(100px);
        transition: opacity 200ms ease, transform 100ms ease;

        &__actions {
          @apply mr-4 my-2 overflow-hidden rounded-r-sm;
        }
      }
    }

    @include mq(1024, max) {
      @apply flex-col justify-start items-start;

      #{$self}__item {
        @apply mx-0 my-1 w-full;
      }
    }
  }

  &--language {
    @include mq(1024, max) {
      @apply flex;

      li {
        @apply mr-4;

        #{$self}__link {

          .icon {
            @apply inline-block;
          }

          &--button {
            @apply text-center;
          }
        }
      }
    }

    @include mq(1024) {
      @apply absolute left-0 top-100 shadow-xl bg-white rounded-sm -ml-4 py-2 px-4 text-left pointer-events-none opacity-0;

      transform: translateY(100px);
      transition: opacity 200ms ease, transform 100ms ease;

      li {
        @apply my-2;
      }
    }
  }
}
