.segment {
  $self: &;

  &__text {
    @apply w-full;
  }

  &__circle {
    @apply flex items-center relative overflow-hidden;
    @apply rounded-full p-2 mx-auto;
    @apply text-white text-center font-600 leading-snug text-base;

    height: px-to-rem(140px);
    width: px-to-rem(140px);

    #{$self}--preset-1 & {
      background-color: $brand-color-1;
    }

    #{$self}--preset-2  & {
      background-color: $brand-color-2;
    }

    #{$self}--preset-3  & {
      background-color: #43b02a;
    }

    #{$self}--preset-4 & {
      background-color: #00a1de;
    }

    #{$self}--preset-5 & {
      background-color: #af9800;
    }

    &::after {
      @apply absolute left-0 top-0 right-0 bottom-0 block z-0 bg-black opacity-0;

      content: '';
      transform: translate3d(0, 0, 0);
      transition: opacity 200ms ease-in-out;
    }

    a#{$self}:hover & {
      @apply transition-all ease-in-out duration-200;

      transform: translateY(px-to-rem(-4px));

      &::after {
        @apply opacity-10;
      }
    }
  }

  &__container {
    @apply inline-flex flex-wrap justify-center;
    @apply shadow-lg rounded;
    @apply -mx-2 p-4 pt-8;

    & > * {
      @apply mx-2 mb-4;

      min-width: px-to-rem(200px);
    }
  }

  &__wrapper {
    @apply flex justify-center;
    @apply mb-8 mt-4 ;
  }
}
