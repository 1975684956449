.band--header {

  &--preset-1 {
    @apply flex items-end pb-0 overflow-visible;

    // @extend .spacing;

    height: 50vh;
    max-height: 440px;

    .band__image {
      @apply opacity-100;
    }

    .band__content {
      @apply relative text-center;
      @apply self-end w-full;
      @apply p-4;

      @screen md {
        @apply self-center;

        @if $rtl {
          @apply text-right;
        } @else {
          @apply text-left;
        }
      }

      h1 {
        text-shadow: 1px 2px 6px rgba(0, 0, 0, .2);
      }

      p {
        text-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
      }

      @include mq(480, max) {

        h1.title--preset-1 {
          font-size: px-to-rem(29px);
        }
      }

      .title {

        &--preset-1 {
          @apply mb-6;

          @screen md {
            @apply mb-8;
          }
        }

        &--preset-4 {
          @apply font-500;
        }
      }
    }
  }
}
