.list {

  $self: &;

  &--preset-5 {
    @apply font-2 leading-normal;

    li {
      @apply mb-2 text-2xs;

      &::before {
        @apply text-brand-1;
      }
    }

    &#{$self}--icon {

      li {

        &::before {
          @apply bg-brand-1;
        }
      }
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }
}
