.lightbox {
  @apply fixed left-100 top-100;
  @apply transform translate-x-full opacity-0;
  @apply bg-white rounded;
  @apply transition-opacity duration-200;

  max-height: calc(100vh - 4rem);
  max-width: calc(100vw - 2rem);
  min-height: 280px;
  width: 640px;
  z-index: 999999;

  .body--showLightbox & {
    @apply left-50 top-50;
    @apply transform -translate-x-1/2 -translate-y-1/2;
    @apply opacity-100;
  }

  &__bg {
    @apply fixed inset-0 opacity-0 transform translate-x-full;
    @apply h-full w-full;
    @apply transition-opacity duration-200;

    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .5);
    z-index: 999998;

    .body--showLightbox & {
      @apply opacity-100 translate-x-0;
    }
  }

  &__button {
    @apply absolute right-0 top-0;
    @apply transform translate-y-4 -translate-x-4;
    @apply h-6 w-6;
    @apply appearance-none outline-none;
    @apply bg-center bg-no-repeat bg-contain;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{rgba($brand-color-1,.99)}' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  &__inner {
    @apply p-4;
    @apply overflow-auto;

    max-height: calc(100vh - 4rem);

    @screen sm {
      @apply px-8;
    }
  }

  // &-content {

  // }

  .b-grid {
    @apply -mx-4;

    @screen xl {
      @apply -mx-8;
    }

    &--preset-2 {
      @apply flex-wrap;

      @include mq(480) {

        & > .col {
          @apply w-1/2;
        }
      }
    }
  }
}

.body--lightbox {
  @apply flex items-center justify-center;
  @apply bg-grey-300 #{!important};
  @apply py-4;

  @screen sm {
    @apply px-4 py-8;
  }

  min-height: 100vh;

  .lightbox-content {
    @apply bg-white rounded p-4;

    max-width: calc(100vw - 2rem);
    min-height: 280px;
    width: 640px;
    // max-height: calc(100vh - 4rem);

    @screen sm {
      @apply px-8;
    }

    .b-grid {
      @apply -mx-4;

      @screen xl {
        @apply -mx-8;
      }

      &--preset-2 {
        @apply flex-wrap;

        @include mq(480) {

          & > .col {
            @apply w-1/2;
          }
        }
      }
    }
  }
}
