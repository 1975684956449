.characteristics {

  > * {
    @apply mb-8;

    &:first-child {

      .popover {

        .popover__content {
          left: -20px;
          transform: translateX(0) translateY(-100px);

          &::after {
            left: 30px;
          }
        }

        .popover__trigger:hover + .popover__content {
          transform: translateX(0) translateY(0);
        }
      }
    }
  }

  @include mq(1280, max) {

    .popover {

      .popover__content {
        left: -20px;
        transform: translateX(0) translateY(-100px);

        &::after {
          left: 30px;
        }
      }

      .popover__trigger:hover + .popover__content {
        transform: translateX(0) translateY(0);
      }
    }
  }

  @screen lg {
    @apply flex flex-wrap text-xs;

    > * {
      @apply w-1/2 pr-8;
    }
  }

  @screen xl {

    > * {
      @apply w-1/3 mb-0;
    }
  }

  &__title {
    @apply mb-4 font-700 text-basesm;

    @screen 2xl {
      @apply text-base;
    }
  }
}

.characteristic {
  @apply flex border-grey-500 border-b border-dashed py-2 px-1;

  &__info {
    @apply mr-4 w-4;
    @if $rtl {
      @apply mr-0 ml-4;
      order: 3;
    }

    .popover {
      @apply absolute; // Needed for the popout of a non overflowing div

      transform: translateY(.125rem);
    }

    .icon {
      @apply inline-block w-4 h-4;
    }
  }

  &__label {
    @apply mr-4 font-700;
    display: flex;
    align-items: center;
    @if $rtl {
      @apply mr-0 ml-4;
      order: 1;
    } 
  }

  &__value {
    @apply  ml-auto;
    @if $rtl {    
      order: 2;
    }
  }
}
