$theme: '1';
$rtl: false;

@import 'themes';
@import 'mixins';

@tailwind base;

@import 'layout/';

@tailwind components;

@import 'components/';

@tailwind utilities;

@import 'vendor/';

