.list {

  $self: &;

  &--preset-1 {
    @apply font-2 leading-normal text-xs;

    @screen md {
      @apply text-sm;
    }

    li {
      @apply mb-2;

      &::before {
        @apply text-brand-1;
      }
    }

    &#{$self}--icon {

      li {

        &::before {
          @apply bg-brand-1;
        }
      }
    }

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }
}
