.card {
  $self: &;

  &--preset-3 {
    @apply max-w-md;

    @screen sm {
      @apply flex items-start;
      @apply max-w-content-small;
    }

    #{$self}__image {
      @apply px-0;

      @screen sm {
        @apply px-0 flex-initial w-1/4 flex-shrink-0;
      }
    }

    #{$self}__body {
      @apply flex flex-wrap flex-auto;
      @apply py-2;

      @screen sm {
        @apply py-0 pl-4;
      }
    }

    #{$self}__title {
      @apply w-full;

      @extend .spacing--preset-s;

      @if $rtl {
        @apply text-right #{!important};
      }

      h3 {
        @extend .title--preset-6;

        a {

          &:hover {
            @apply text-brand-2;
          }
        }
      }
    }

    #{$self}__meta {
      @apply w-full order-first;

      // @extend .spacing--preset-s;

      #{$self}__date {
        @apply opacity-50; 

        @extend .text--preset-small;
        @extend .spacing--preset-s;

        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 3px;
          + .icon {
            margin-left: 15px;
          }
        }
      }

      .button {
        @extend .spacing--preset-s;
      }
    }
  }
}
