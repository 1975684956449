.button {

  &--sticky {
    @apply bg-brand-2 text-white text-center;
    @apply rounded-r-none;
    @apply shadow-xl leading-tight w-16 h-16;
    @apply flex flex-wrap items-center;
    @apply p-0 #{!important};

    @screen md {
      @apply w-20 h-20;
      @apply px-1 py-2  #{!important};
    }

    .icon {
      @apply h-6 w-6 inline-block text-white fill-current ;
      @apply my-1 mx-auto;

      @screen md {
        @apply h-10 w-10;
      }
    }

    div {
      @apply w-full text-mini font-400 uppercase;

      @screen md {
        @apply text-xs;
      }
    }

    &:hover {
      @apply bg-brand-2 text-white;
    }

    & + & {
      @apply mt-2;
    }
  }
}
