/* purgecss start ignore */

.band--navigation {
  @apply overflow-visible bg-white  sticky top-0;
  @apply z-40 py-0;
  @apply border-b border-solid border-grey-100;

  .band__content {
    max-width: none;
  }

  &__buttons {
    @apply fixed right-0 bottom-0;
    @apply mt-4 w-16 h-20;

    transform: translateY(-4.5rem);

    @screen md {
      @apply right-0 top-0 bottom-auto;
      @apply w-20;

      transform: translateY(calc(50vh - 40px));
    }

    @include mq(1920) {
      @apply right-auto left-50;

      margin-left: 960px;
      transform: translateY(calc(50vh - 40px));
    }

    .body--show-menu & {
      @apply hidden;
    }
  }
  .has-filters-open & {
    @apply z-0;
  }
}

.b-grid--navigation {
  @apply justify-between items-center flex-wrap;

  .col--logo {
    @apply flex justify-between items-center;

    .navigation__logo {
      @apply flex-auto;

      .icon {
        height: 55px;
        @screen xl {
          height: 76px;
        }
        width: 120px;
      }

      img {
        max-height: 55px;
        @screen xl {
          max-height: 76px;
        }
      }
    }

    &--concern {
      & + .col--navigation {
        margin-right: 120px;
      }

      .navigation__logo {
        @apply flex-auto;
      }

      .navigation__logo--concern {
        @apply flex-initial flex items-center;
        @apply mx-4;

        @include mq(480, max) {
          @apply hidden;
        }

        img {
          max-height: 48px;
          max-width: 80px;
          width: 100%;

          @screen lg {
            max-height: 56px;
            max-width: 100px;
            width: 100%;
          }
        }

        .body--show-menu & {
          @apply hidden;
        }

        @screen lg {
          @apply absolute right-0 top-0 bottom-0;
          @apply mr-1;

          // width: 100px;
          margin-top: 32px;

          @if $rtl {
            @apply absolute left-0 top-0 bottom-0;
            margin-top: 0;
            right: auto;
          }
        }
      }
    }

    .languageswitch {
      @apply flex-initial order-last mx-4;
    }

    .navigation__close,
    .navigation__open {
      @apply order-last;
      @apply hidden;
    }
  }

  .col--navigation {
    @apply py-4 flex-auto;
  }

  .col--topbar {
    @apply order-first p-0 -mx-4 bg-grey-200;
    z-index: 100;
    width: calc(100% + 2rem);

    @screen xl {
      @apply w-full mx-0;
    }
  }
}

@include mq(1024, max) {
  .band--navigation {
    .b-grid--navigation {
      @apply block;

      .col--logo {
        @apply relative z-10 bg-white;

        .navigation__logo {
          .icon {
            height: 60px;
            width: 100px;
          }

          img {
            max-height: 60px;
          }
        }

        .navigation__close,
        .navigation__open {
          .icon {
            @apply inline-block w-8 h-8;
          }
        }

        .navigation__open {
          @apply block;
        }
      }

      .col--navigation {
        @apply border-b border-brand-2 border-solid mb-4 pt-0;
      }

      .col--topbar {
        @apply order-last mx-4 w-auto bg-transparent;
      }

      .col--navigation,
      .col--topbar {
        @apply pointer-events-none opacity-0 absolute;

        transform: translateY(-100%);
        transition: opacity 200ms ease, transform 200ms ease;
      }
    }

    .body--show-menu & {
      @apply fixed bottom-0 left-0 right-0  bg-brand-3 overflow-auto;

      .b-grid--navigation {
        .navigation__open {
          @apply hidden;
        }

        .navigation__close {
          @apply block;
        }

        .col--logo {
          @apply bg-brand-3;
        }

        .col--navigation,
        .col--topbar {
          @apply pointer-events-auto opacity-100 relative;

          transform: translateY(0);
        }
      }
    }
  }
}

/* purgecss end ignore */
