.button {
  @apply px-6 py-4;

  &--sm {
    @extend .text--preset-small;

    @apply px-2 py-1;
  }

  &--lg {
    @apply px-8 py-5;
  }

  &--xl {
    @extend .text--preset-intro;

    @apply px-8 py-6;
  }
}
