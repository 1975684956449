[data-accordion] {
  @apply border-t border-b border-grey-300;
}

[data-accordion-item] {
  @apply w-full overflow-hidden;

  &:not(:last-child) {
    @apply border-b border-grey-300;
  }

  & > input {
    @apply absolute -z-1 opacity-0;
  }

  & > label {
    @apply bg-white;
    @apply flex justify-start items-center;
    @apply cursor-pointer;
    @apply py-4 select-none;

    &:hover {
      @apply text-grey-600;
    }

    & > .icon {
      @apply block w-6 h-6 relative mr-2;

      &--minus {
        @apply hidden text-brand-1;
      }

      svg {
        @apply w-full fill-current;
      }
    }
  }

  & > input:checked + label > .icon,
  &:focus-within > label > .icon{
    &--minus {
      @apply block;
    }

    &--plus {
      @apply hidden;
    }
  }
}

[data-accordion-panel] {
  @apply border-t border-white;

  max-height: 0;
  transition: padding .25s ease;
}


[data-accordion-item] > input:checked ~ [data-accordion-panel],
[data-accordion-panel]:focus-within {
  @apply max-h-full py-4;
  @apply border-grey-300;
}
