/* purgecss start ignore */

.input-icon {
  @apply absolute top-50 right-0 pointer-events-none;
  @apply hidden;
  @apply mr-3;
  @if $rtl {
   right:auto;
   left: 0.75rem;
  }
  transform: translateY(-50%);

  .icon {
    @apply h-5 w-5 hidden fill-current;
  }

  // DEFAULT

  .interacted:required + & {
    @apply block;
  }

  .interacted:required:valid + & {

    .icon--check {
      @apply text-status-ok block;
    }
  }

  .interacted:required:invalid + & {

    .icon--times {
      @apply text-status-alert block;
    }
  }

  // SELECTBOX

  select + & {
    @apply block;

    .icon {
      @apply h-6 w-6 text-grey-400 block;
    }
  }

  select.interacted:required:invalid + & {

    .icon {
      @apply text-status-alert;
    }
  }

  // CHECKBOX

  .label & {
    @apply block right-auto left-0 w-5 h-5 top-0;

    transform: translateY(0);

    .icon {
      @apply block text-status-ok h-4 w-3;

      margin-left: 5px;
      margin-top: 3px;
    }
  }

  // INLINE ICON

  .input--icon & {
    @apply flex items-center text-brand-2;

    .icon {
      @apply flex items-center w-4 h-4;

      > svg {
        @apply flex-1;
      }
    }
  }
}

/* purgecss end ignore */
