.news {
  $self: &;

  &--detail {
    .band & {
      &.band__content {
        @apply max-w-content-small mx-auto;
      }
    }
    @apply max-w-content-small mx-auto;

    #{$self}__image,
    #{$self}__title,
    #{$self}__body {
      @extend .spacing--preset-l;
    }

    #{$self}__title {
      @apply flex flex-col-reverse;
    }

    #{$self}__share {
      @apply border-t-2 border-grey-200;
      @apply mt-8 py-8;
    }
    #{$self}__meta {
      @apply opacity-50;
      @extend .text--preset-small;
      @extend .spacing--preset-s;

      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 3px;
        margin-left: 15px;
      }
    }
  }
}
