.cta {
  $self: &;

  &--pull-up {
    @apply z-10 relative flex text-center;

    margin-top: px-to-rem(-55px);

    @screen sm {
      margin-top: px-to-rem(-50px);
    }

    @screen md {
      margin-top: px-to-rem(-120px);
    }

    @screen lg {
      margin-top: px-to-rem(-120px);
    }
  }

  &__container {
    @apply p-4 shadow-lg bg-white rounded text-center mx-auto ;

    @screen sm {
      @apply p-6;

      max-width: px-to-rem(840px);
    }
  }

  & &__title {
    @extend .title--preset-6;

    @apply mb-4 w-full;

    + #{$self}__button {
      @apply mt-0;
    }
  }

  &__button {
    @apply mt-4;
  }

  &__image {
    @apply m-0 mb-4 mx-auto w-full;

    max-width: px-to-rem(150px);

    @screen sm {
      max-width: px-to-rem(175px);
    }

    img {
      @apply w-full;
    }

    #{$self} & + .content {
      @apply text-left;

      @screen sm {
        @apply ml-8;
      }
    }

    + #{$self}__button {
      @apply mt-0;
    }
  }

  &__content {
    @apply w-full;

    @screen sm {
      @apply inline-flex flex-no-wrap items-start;
      @apply w-full text-xs mx-auto;
    }

    .content {
      @apply mx-auto;
      @apply flex-grow;
    }

    .content > * {
      font-size: inherit;

      @apply text-xs;

      &:last-child {
        @apply mb-0;
      }
    }
  }

  &--preset-2  {
    @apply w-full block py-2 px-4;
    @apply shadow-lg bg-white rounded text-center;

    @screen md  {
      @apply w-auto;
    }

    p {
      @apply opacity-75 text-sm block w-full text-center;

      @screen md {
        @apply text-base;
      }
    }

    #{$self}__title {
      @apply mb-0 text-brand-1 text-base block;

      @screen md {
        @apply text-lg;
      }
    }

    &.cta--pull-up {
      margin-top: -1rem;

      @screen md {
        @apply relative top-0 left-50 inline-block w-auto;
        @apply transform -translate-y-1/2 -translate-x-1/2;

        margin-top: 0;
      }
    }
  }
}

.band--usp-preset-2 {
  @apply relative;

  @screen md {
    margin-bottom: -2rem;
  }
}
