/* purgecss start ignore */

.map-holder {
  @apply relative;
  @apply h-0 w-full;

  padding-bottom: 100%;

  @screen lg {
    padding-bottom: 80%;
  }

  .map {
    @apply absolute z-10 left-0 right-0 bottom-0 top-0;
    @apply flex items-center justify-center;

    &--placeholder {
      @apply absolute z-0 left-0 right-0 bottom-0 top-0 pointer-events-none;

      img {
        @apply object-cover h-full w-full;
      }
    }
  }

  .loader {
    @apply inline-block rounded-sm pointer-events-none;
    @apply bg-brand-1 p-2 text-white text-2xs font-700;

    .loaded & {
      @apply hidden;
    }
  }

  .infowindow {
    @apply absolute z-20 left-0 right-0 bottom-0 top-0 flex justify-center items-center;
    @apply p-8 pointer-events-none;
    @apply transition-opacity duration-300 opacity-0;

    &__card {
      @apply relative z-20;
      @apply bg-white p-4 rounded-sm shadow-lg;
      @apply max-w-full w-84 opacity-0;
      @apply transition-opacity duration-300 delay-100;
    }

    &__body {

      p {
        @apply block;
        @apply leading-relaxed #{!important};
      }

      .button {
        @apply mt-4;
      }
    }

    &--close {
      @apply absolute left-0 right-0 bottom-0 top-0 z-0 w-full h-full block;
      @apply border-0 outline-none;
    }

    &--show {
      @apply flex pointer-events-auto opacity-100;
      background-color: rgba(0, 0, 0, .3);

      .infowindow__card {
        @apply opacity-100;
      }
    }
  }
}

/* purgecss end ignore */
