.list {

  $self: &;

  &--preset-3:not(.list--icon) {

    li {
      @apply mb-2;

      @screen sm {
        @apply inline-block;

        &:not(:last-child) {
          @if $rtl {
            @apply pl-2 ml-2;
          } @else {
            @apply pr-2 mr-2;
          }
        }
      }
    }
  }

  &--icon.list--preset-3 {

    // @extend .text--preset-intro;

    li {
      @apply relative p-0 leading-none mb-4;

      @if $rtl {
        @apply pr-10 ml-4;
      } @else {
        @apply pl-10 mr-4;
      }

      &::after,
      &::before {
        @apply top-50 mt-0;

        transform: translateY(-50%);
      }

      &::before {
        @apply bg-brand-2;
      }

      @screen md {
        @apply inline-block;

        // border: 1px solid green;
      }
    }
  }
}
