.b-collection {
  $self: &;

  &--preset-3 {
    @apply flex flex-wrap;
    @apply max-w-content-small mx-auto;

    .band__content & {
      @apply -mx-2;

      @screen lg {
        @apply mx-auto;
      }
    }

    .item {
      @apply flex-initial w-full;
      @apply px-2 py-1;

      @extend .spacing--preset-s;

      @screen md {

        &:not(:first-child) {
          @apply flex-initial w-1/3;

          figcaption {
            @apply text-base #{!important};
          }
        }
      }
    }
  }
}
