.taglist {
    
    &__title {
        @apply font-700 text-base mb-3;
    }

    &__item-container {
    }

    &__item {
        @apply inline-block pb-3 pr-2;

        &:last-child {
            @apply pr-0
        }
    }

    &__button {
        @apply font-400;
    }
}