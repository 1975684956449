.quote {
  @apply mx-auto;

  max-width: px-to-rem(960px);

  &:not(:last-child) {
    @apply mb-8;
  }

  @screen md {
    @apply flex;
  }

  &__content {
    @screen md {
      @apply pr-6 order-first flex-shrink-0;

      width: 77%;
    }

    @screen lg {
      @apply pr-8;
    }
  }

  &__text {
    @apply text-base italic max-w-full;
    @apply relative px-4 py-8;

    @screen md {
      @apply text-lg px-8;
    }

    &::before,
    &::after {
      @apply absolute text-7xl leading-none text-grey-600;

      font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
    }

    &::before {
      @apply left-0 top-0;

      content: '“';
    }

    &::after {
      @apply right-0;

      bottom: px-to-rem(8px);
      content: '„';
    }
  }

  &__footer {
    @apply text-right;
  }

  &__footer-inner-container {
    @apply relative text-left inline-block pl-6 mr-4 mt-2;

    @screen md {
      @apply mr-8;
    }

    &::before {
      @apply absolute left-0;

      content: '—';
    }
  }

  &__name {
    @apply font-700;
  }

  &__function {
    @apply text-sm;
  }

  &__avatar {
    @screen md {
      @apply pt-8;
    }

    img {
      @apply block p-0 mb-2 mx-auto rounded-full w-full ;

      max-width: px-to-rem(200px);

      @screen md {
        @apply mb-0;

        max-width: px-to-rem(540px);
      }
    }
  }
}
