.b-grid {

  &--media,
  &--fullmedia {
    @apply items-center;

    .col {
      @apply w-full;

      @screen lg {
        @apply w-1/2;
      }

      img {
        @apply w-full;
      }
    }
  }

  &--fullmedia {
    @screen lg {

      .col {
        @apply relative flex flex-row-reverse mx-0 w-1/2 px-4;
        // border: 2px solid red;

        // Using .flex-row-reverse instead of .justify-end for IE11

        .video--embed,
        > .figure {
          @apply flex-shrink-0 float-right;
          @apply w-half-screen max-w-content-small pl-12;
        }

        & + .col {
          @apply justify-start flex-row;

          .video--embed,
          > .figure {
            @apply pl-0 pr-12;
          }
        }
      }
    }
  }

  &--media-text,
  &--fullmedia-text {
    @apply max-w-content-large mx-auto;
    @apply items-center;

    .col--content {
      @apply mx-auto max-w-5xl;
    }

    .col--media {
      @apply w-full order-last;

      @screen lg {
        @apply w-1/2 order-none;
      }

      img {
        @apply w-full;
      }
    }
  }

  &--fullmedia-text {
    @screen lg {

      .col--media {
        @apply relative flex flex-row-reverse mx-0 w-1/2 px-4;

        // Using .flex-row-reverse instead of .justify-end for IE11

        .video--embed,
        > .figure {
          @apply flex-shrink-0 float-right;
          @apply w-half-screen max-w-content-small pl-12;
        }
      }

      .col--content + .col--media {
        @apply justify-start flex-row;

        .video--embed,
        > .figure {
          @apply pl-0 pr-12;
        }
      }
    }
  }
}
