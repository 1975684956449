@import 'custom', 'fields', 'icon';

.input {
  @apply flex flex-wrap mb-4;

  &__label {
    @apply w-full;
    @apply mb-1;
  }

  &__field {
    @apply w-full;
    @apply relative;
  }
}

.text-preset-label {
  @apply font-2 text-xs leading-normal;

  @screen md {
    @apply text-sm;
  }
}

input,
select,
textarea,
.label {
  @apply font-1 leading-normal text-sm;
}

/* purgecss start ignore */

.umbraco-forms-field div label {
  @apply block #{!important};

  & + label {
    @apply mt-2;
  }
}

/* purgecss end ignore */
