.b-grid {

  &--preset-pvc {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full;
    }

    @screen lg {

      & > .col--info {
        width: 27%;
      }

      & > .col--timeline {
        width: 46%;
      }

      & > .col--phase {
        width: 27%;
      }
    }
  }
}
