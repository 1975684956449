.menu {

  $self: &;

  &--preset-2 {

    > #{$self}__item {
      @apply w-full;
      @apply py-1;

      @extend .text--preset-body;

      > a {
        @apply inline-block whitespace-normal;

        @extend .link--preset-menu;
      }

      &--selected {

        > a {
          @apply text-brand-2 font-700;
        }
      }
    }

    @include mq(1024) {
      @apply flex flex-wrap;
      @apply bg-white border border-grey-200 shadow-lg rounded-sm;
      @apply w-64 max-w-xs py-2 px-4;
    }

    @include mq(1024, max) {
      @apply mt-2 ml-4 opacity-80;
    }
  }
}
