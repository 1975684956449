.b-grid {
  @apply flex justify-center;

  .band__content & {
    @apply -mx-4;

    @screen xl {
      @apply -mx-8;
    }
  }

  & > .col {
    @apply px-4 py-2;

    @screen xl {
      @apply px-8;
    }
  }

  @screen lg {

    &--align-top {
      @apply items-start #{!important};
    }

    &--align-middle {
      @apply items-center #{!important};
    }

    &--align-bottom {
      @apply items-end #{!important};
    }
  }
}

@import 'preset-1', 'preset-2', 'preset-3', 'preset-4', 'preset-5', 'preset-6', 'preset-7', 'preset-8', 'preset-9', 'preset-10', 'preset-pvc';
