/* purgecss start ignore */

.continents {
  @apply max-w-full w-full;

  &__map {
    @apply relative text-white max-w-full w-full h-full max-h-full;

    svg {
      @apply relative inline-block h-full w-full;

      fill: #fff;
      pointer-events: none;
      stroke: #555;
      stroke-width: 10pt;
    }

    $continents: 'AF','AU','AS','EU','SA','NA';

    @each $continent in $continents {

      &--active-#{ $continent } {

        [data-id=#{ $continent }] {
          fill: #43b02a;
          pointer-events: initial;

          &:hover {
            fill: lighten(#43b02a, 5%);
            stroke: darken(#43b02a, 5%);
            transition: fill ease-in-out 120ms;
          }
        }
      }
    }
  }
}

/* purgecss end ignore */
