.tags {
  @apply flex items-start text-2xs mb-4;

  &__list {
    @apply flex items-center flex-wrap;
  }

  &__title {
    @apply mr-2 flex-shrink-0 py-1;
  }

  &__tag {
    @apply bg-grey-100 py-1 pl-3 pr-5 mr-2 rounded-sm bg-no-repeat cursor-pointer mb-2 text-2xs ;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23757575' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
    background-position: calc(100% - 7px) 50%;
    background-size: .5rem;

    &:hover {
      @apply bg-grey-200;
    }
  }
}
