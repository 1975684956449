.social {
  $self: &;

  &--share {
    @apply flex items-center;

    #{$self}__title {
      @apply mr-2;

      span {
        @include strongTextClass();
      }
    }
  }
}
