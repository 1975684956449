.list {

  &--locations {

    li {
      @apply mb-1;
    }

    @include mq(480) {
      column-count: 2;
      column-gap: 2rem;
    }

    @screen sm {
      column-count: 3;
    }

    @screen lg {
      column-count: 4;
    }
  }
}

.location--nl {
  @apply mb-6 pb-6 pt-4 border-b border-grey-300;

  strong {
    @apply block mb-2;
    @apply text-sm;

    @screen md {
      @apply text-base;
    }

    @screen xl {
      @apply text-lg;
    }
  }
}

.list--contact {
  @apply text-xs;

  @screen md {
    @apply text-sm;
  }

  li {
    @apply mb-1;

    img {
      @apply max-w-full mb-2;

      max-height: 88px;
    }

    strong {
      @apply text-sm;

      @screen md {
        @apply text-base;
      }
    }

    .button--sm {
      @apply px-4;
    }
  }
}
