/* purgecss start ignore */

.overview {
  @apply flex;

  .rte {
    @apply ml-0;
  }

  &__filters {
    @apply bg-brand-5 px-4 pt-0  top-0 bottom-0 fixed bottom-0 z-10;
    transform: translateX(calc(-100%));
    transition: transform 200ms ease;
    width: calc(100%);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;   

    &.filters--open {
      transform: translateX(0);
    }

    @screen sm {
      @apply w-1/3 relative mr-8 shadow-none px-8;
      transform: translateX(0);
      overflow-y: initial;
      overflow-x: initial;
      display: block;
    }

    @screen lg {
      @apply w-1/4;
    }

    @screen xl {
      @apply w-1/5 mr-12;
    }
    @if $rtl {
      @screen xl {
        @apply w-1/5 mr-0 ml-12;
        margin-right: -1rem;
      }
    }

    &-checkbox {
      @apply absolute hidden;

      &:checked {
        ~ .overview__filters {
          transform: translateX(0);
        }
      }
    }

    &-trigger-holder {
      @apply relative;
      @screen sm {
        @apply hidden;
      }
    }

    &-trigger {
      @apply bg-brand-1 p-4 flex;
      bottom: 1rem;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      right: 0;
      z-index: 2;
      justify-content: center;
      box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
      border-radius: 0.5rem;
      color: #ffffff;
      width: 70vw;

      label {
        @apply flex;
        align-items: center;
        &.stretched-link::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          content: "";
        }
      }

      .icon {
        @apply w-5 h-5 flex items-center mr-2;

        > svg {
          flex: 1 1 auto;
        }
      }
    }
  }

  &__content {
    @apply flex-1 pt-8 pb-8 pl-4;
    container-type: inline-size;
    container-name: overwiew;
    @screen sm {
      @apply pb-20 pl-0;
    }

    .menu--preset-3 {
      @apply mb-8;
    }
  }

  &__search {
    @apply mb-5 pb-5 border-b border-solid border-brand-4;
  }

  &__country {
    //Temporary hide the country select
    //display: none;
    label {
      @apply text-sm font-700 mr-2;
      font-family: inherit;
      line-height: inherit;

      @screen 2xl {
        @apply text-base;
      }
    }
    select + .input-icon .icon {
      color: var(--theme-color-brand2);
    }
  }

  .products {
    @screen sm {
      &__segments--item {
        @apply ml-2;      
      }
    }
    &[aria-busy="true"] {
      @apply relative;

      min-height: 100px;

      &::after {
        @apply w-10 h-10;
        @apply block absolute left-50;
        @apply pointer-events-none;

        animation: loader 0.7s infinite linear;
        border: 5px solid $brand-color-1;
        border-bottom-color: rgba($brand-color-1, 0.6);
        border-left-color: rgba($brand-color-1, 0.6);
        border-radius: 50%;
        border-top-color: rgba($brand-color-1, 0.6);
        content: "";
        top: 80px;
        transform: translateZ(0), translate(-50%, -50%);
        z-index: 99999;
      }

      > * {
        @apply opacity-0;
        @apply transition-opacity duration-100 ease-in-out;
      }
    }
  }
}

.has-filters-open {
  position: fixed;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */
