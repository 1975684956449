.responsibility {

  > * + * {
    @apply mt-10;
  }

  &__content {

    p {
      @apply leading-loose;
    }
  }

  .usp {
    margin-bottom: px-to-rem(-8px);
  }
}
