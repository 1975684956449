
.card-contact {
  $self: &;

  @apply border border-grey-200 border-solid px-4 py-6 block bg-white;
  @apply max-w-3xl mx-auto;

  &__image {
    @apply text-center;

    img {
      @apply w-full;
    }
  }

  &__image--has-overlay {
    @apply relative;
  }

  &__overlay {
    @apply absolute block w-1/3 right-0 top-0;

    max-width: px-to-rem(90px);
  }

  &:hover {
    @apply shadow-xl;
  }

  &__content {

  }

  &__button {
    @apply mt-4 flex;

    &--center {
      @apply justify-center;
    }

    &--right {
      @apply justify-end;
    }
  }
}
