.menu {

  $self: &;

  &--preset-1 {
    @apply flex flex-wrap;

    > #{$self}__item {
      @apply whitespace-no-wrap;
      @apply relative z-10;

      /* purgecss start ignore */
      font-size: 18px;
      font-size: clamp(13px, 1.6vw, 18px);
      font-size: clamp(1rem, 0.75vw + 0.25rem, 1.125rem);
      margin-right: 1rem;
      //margin-right: clamp(2rem, 1.5rem, 3rem);
      @screen md {
        margin-right: 1.5rem; 
      }
      @screen xl {
        margin-right: 2rem; 
      }

      /* purgecss end ignore */

      > a {
        @extend .link--preset-menu;
      }

      &--selected {

        > a {
          @apply text-brand-2;

          @include strongTextClass();
        }
      }

      &.has-submenu {

        > a {
          @apply relative pr-5;

          &::after {
            @apply absolute top-50 right-0;
            @apply bg-contain bg-no-repeat bg-center;
            @apply inline-block w-4 h-4 opacity-75;
            @apply ml-1;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23333333' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
            content: '';
            transform: translateY(-50%);
          }
        }
      }

      > ul {
        @include mq(1024) {
          @apply absolute top-100 left-0 opacity-0 pointer-events-none -ml-4;

          transform: translateY(50px);
          transition: opacity 200ms ease, transform 100ms ease;
        }
      }

      &:hover {
        @apply z-20;

        > ul {
          @apply pointer-events-auto opacity-100;

          transform: translateY(0);
        }
      }
    }

    @include mq(1024, max) {
      @apply block;

      > #{$self}__item {
        @apply py-2 my-0 mr-0 border-t border-brand-4 border-solid;

        font-size: clamp(16px, 1.8vw, 20px);
      }
    }
  }
}
