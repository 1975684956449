/* purgecss start ignore */

.holder {

  &--align-left {
    @apply text-left;

    .list {
      @apply mx-auto;
      @apply max-w-content-small;
    }
  }

  &--align-center {
    @apply text-center;
  }

  &--align-right {
    @apply text-right;
  }

  // Allow for lists to be aligned within holder

  .list {
    @apply whitespace-pre-line;
    @apply -mt-6;

    li {
      @apply inline-block;
    }
  }
}

/* purgecss end ignore */
