.comparison-table {
  $border-min: 30px;
  $border-base: 3vw;
  $border-max: 60px;

  @apply pb-16 text-2xs;

  border-collapse: separate;
  border-spacing: clamp(#{$border-min}, #{$border-base}, #{$border-max}) 0;
  margin-left: clamp(-#{$border-max}, -#{$border-base}, -#{$border-min});
  margin-right: clamp(-#{$border-max}, -#{$border-base}, -#{$border-min});
  width: 190%; // Use this percentage to show a small section of the next column in table

  @screen md {
    @apply text-xs;

    width: 130%;
  }

  @screen lg {
    width: calc(100% + (clamp(#{$border-max}, #{$border-base}, #{$border-min}) * 2) - 4rem);
  }

  @screen xl {
    @apply text-sm;

    width: calc(100% + (clamp(#{$border-max}, #{$border-base}, #{$border-min}) * 2) - 2rem);
  }

  /* IE Edge non Chromium */
  @supports (-ms-ime-align:auto) and (-webkit-text-stroke:initial) {
    border-spacing: 45px 0 #{ !important};
    margin-left: -45px #{ !important};
    margin-right: -45px #{ !important};
    width: 100% #{ !important};
  }

  &__wrapper {
    @apply overflow-auto -ml-4 pl-4;

    width: calc(100% + 4rem);

    @screen lg {
      @apply w-full overflow-visible;
    }
  }

  &__title {

    td {

      &:first-child {
        @apply text-left pt-6 font-700 text-xs;

        @screen md {
          @apply text-sm;
        }

        @screen xl {
          @apply text-base;
        }
      }
    }
  }

  &__col {

    &--label {
      padding-left: px-to-rem(32px);

      .icon {
        @apply inline-block w-4 h-4;
      }

      .popover {
        margin-left: px-to-rem(-32px);
      }
    }

    &-available {
      @apply relative;

      &__shadow {
        @apply absolute bottom-0 bg-white -z-1 shadow-2xl;

        bottom: 0;
        height: 100px;
        left: -10px;
        right: -10px;
      }
    }

    &-underscroll {
      @apply bg-white absolute left-100 bottom-0 z-20;

      box-shadow: 14px 10px 10px -1px rgba(0, 0, 0, .05);
      width: 10px;

      @screen lg {
        @apply hidden;
      }
    }

    &-border {
      @apply bg-white absolute right-100 bottom-0 z-20;

      width: 2rem;

      @screen lg {
        @apply hidden;
      }
    }
  }

  &__label {
    @apply flex items-center;

    .icon {
      @apply mr-4;
    }

    .popover {

      .popover__content {
        left: -20px;
        transform: translateX(0) translateY(-100px);

        &::after {
          left: 30px;
        }
      }

      .popover__trigger {
        @apply flex items-center;

        &:hover + .popover__content {
          transform: translateX(0) translateY(0);
        }
      }
    }
  }

  tbody {

    tr:not(.comparison-table__title) {

      td {
        @apply border-b border-dashed border-grey-400 py-2 w-1/4;

        &:not(.comparison-table__col--label) {
          @apply text-center font-700;
        }
      }
    }
  }

  tfoot td:first-child {
    @apply z-10;

    &::after {
      @apply bg-white absolute left-0 right-0 top-0;

      content: '';
      height: 60px;
    }
  }

  td:first-child,
  th:first-child {
    @apply sticky left-0 bg-white z-20;
  }

  th {
    @apply pt-4;

    .title {
      @apply block;
    }
  }
}
