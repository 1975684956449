.usp {
  $self: &;

  @apply flex justify-center;

  &--pull-up {
    @apply z-10 relative;

    margin-top: px-to-rem(-35px);
  }

  &__container {
    @apply p-4 pb-0 shadow-lg bg-white rounded;

    @screen md {
      @apply p-6;
    }

    #{ $self }--clean & {
      @apply p-0 shadow-none;
    }

    @screen lg {
      @apply inline-flex flex-no-wrap items-start p-8 pb-0;
    }

    > * {
      @apply mb-6 ;

      @screen lg {
        @apply flex-grow flex-shrink w-full mb-8 mr-8;

        max-width: px-to-rem(350px);
      }

      &:last-child {
        @screen lg {
          @apply mr-0;
        }
      }
    }
  }
}
