.potato {
  $self: &;

  &--preset-1 {

    #{ $self }__icon-container {
      @apply w-10 h-12 mr-2;
    }

    & #{ $self }__icon {
      @apply pl-2;

      svg {
        max-height: px-to-rem(25px);
        max-width: px-to-rem(25px);
      }
    }

    & #{ $self }__icon-background {

      svg {
        @apply w-10 h-12;
      }
    }

    & #{ $self  }__title {
      @apply text-sm mb-0 leading-normal;
    }

    & #{ $self }__text {
      @apply text-2xs leading-snug;
    }
  }
}
