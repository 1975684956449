.rte {
  $self: &;

  .text-center &,
  &.text-center {
    margin-left: auto;
    margin-right: auto;
  }

  @apply my-0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .spacing;

    a {
      @extend .link--preset-title;
    }
  }

  h1 {
    @extend .title--preset-1;

    + p {
      @extend .text--preset-intro;
    }
  }

  h2 {
    @extend .title--preset-2;
  }

  h3 {
    @extend .title--preset-3;
  }

  h4 {
    @extend .title--preset-4;
  }

  h5 {
    @extend .title--preset-5;
  }

  h6 {
    @extend .title--preset-6;
  }

  p {
    @extend .text--preset-body;
    @extend .spacing;
  }

  a {
    @extend .link--preset-1;
  }

  blockquote {
    @extend .text--preset-quote;
    @extend .spacing;

    // + p {
    //   @apply -mt-3;

    //   &::before {
    //     @apply mr-2;

    //     content: '\2014';
    //   }
    // }
  }

  ul,
  ol {

    > br {
      @apply hidden;
    }

    @extend .list--preset-1;
  }

  ul {
    @extend .list--bullets;
    @extend .spacing;
  }

  ol {
    @extend .list--numbers;
    @extend .spacing;
  }

  &#{$self}--potato-value-chain {

    * {
      font-size: px-to-rem(14px);
      line-height: px-to-rem(22px);
    }

    ul {
      @apply inline-block;

      li {
        @apply pl-6 table;

        &::before {
          color: inherit;
          font-size: px-to-rem(14px);
          line-height: px-to-rem(22px);
        }
      }
    }

    a {
      color: inherit;
    }
  }
}
