/* purgecss start ignore */

.print {
  @apply hidden;
}

@font-face {
  font-family: 'agrico_tuberosumregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/agrico_tuberosum-webfont.woff2') format('woff2'), url('../fonts/agrico_tuberosum-webfont.woff') format('woff');
}

/* purgecss end ignore */
