.valueinfo {
  $self: &;

  @apply rounded bg-brand-2;
  @apply text-white;
  @apply p-4 pt-8;
  @apply mb-8;

  @screen lg {
    @apply px-8 py-6;

    max-width: px-to-rem(350px);
  }

  background-color: #ac675e;

  &__title {
    @apply font-700;
    @apply mb-4;

    font-size: px-to-rem(26px);
    line-height: px-to-rem(30px);

    @screen lg {
      font-size: px-to-rem(35px);
      line-height: px-to-rem(40);
    }
  }

  &__content {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);

    &.rte {

    }
  }

  &--preset-1 {
    background-color: #c99272;
  }
}
