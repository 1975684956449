@import 'preset1', 'preset2', 'preset3', 'preset4' ,'preset5','preset6','preset7', 'locations';

.list {

  &--numbers {
    counter-reset: list--numbers-counter;

    li {
      @apply relative pl-8;

      counter-increment: list--numbers-counter;

      @if $rtl {
        @apply pr-8;
      } @else {
        @apply pl-8;
      }

      &::before {
        @apply absolute top-0;

        content: counter(list--numbers-counter) '. ';

        @if $rtl {
          @apply right-0 mr-2;
        } @else {
          @apply left-0 ml-2;
        }
      }
    }
  }

  &--bullets {

    li {
      @apply relative;

      @if $rtl {
        @apply pr-8;
      } @else {
        @apply pl-8;
      }

      &::before {
        @apply absolute top-0;
        @apply leading-none text-base;

        @if $rtl {
          @apply right-0 mr-2;
        } @else {
          @apply left-0 ml-2;
        }

        @screen md {
          @apply text-lg;
        }

        content: '\2022';
      }
    }
  }

  &--icon {

    li {
      @apply relative pl-10 py-1;

      @if $rtl {
        @apply pr-10;
      } @else {
        @apply pl-10;
      }

      &::before {
        @apply absolute top-0 -mt-1;
        @apply w-8 h-8 block rounded-full;

        content: '';

        @if $rtl {
          @apply right-0;
        } @else {
          @apply left-0;
        }
      }

      &::after {
        @apply absolute top-0 mt-1;
        @apply w-4 h-4 block;
        @apply bg-contain bg-no-repeat bg-center;

        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E");
        content: '';

        @if $rtl {
          @apply right-0 mr-2;
        } @else {
          @apply left-0 ml-2;
        }
      }

      @screen md {

        &::before {
          @apply mt-0;
        }

        &::after {
          @apply mt-2;
        }
      }
    }
  }
}
