/* purgecss start ignore */

.video--embed {
  @apply max-w-content relative;
  // @apply overflow-hidden;

  .video-link {
    @apply relative block bg-black;

    @include mediaPresets();

    .figure {
      @apply block;
    }

    img {
      @apply w-full relative z-0 opacity-75;

      @include mediaPresets();

      transition: opacity 150ms ease-in-out;
    }

    .icon {
      @apply block h-12 w-12;
      @apply text-white;
      @apply mx-auto mb-4;

      @screen md {
        @apply h-16 w-16;
      }

      svg {
        @apply fill-current;
      }
    }

    &__info {
      @apply flex justify-center items-center;
      @apply absolute left-0 right-0 top-0 bottom-0 z-10 p-4;
      @apply text-center text-white;

      span {
        @extend .title--preset-5;

        .col--media & {
          @extend .title--preset-6;
        }
      }
    }

    &:hover {

      img {
        @apply opacity-50;
      }
    }
  }

  &:not(.active) {

    .video-player {
      @apply hidden;
    }
  }

  .video-info {
    @apply text-center leading-snug opacity-0 mt-1 mb-4;
    @apply transition-opacity duration-500 h-0;

    transition-delay: 2s;

    span {
      @apply inline-block;
      @apply transform -translate-y-8;
      @apply transition-all duration-500;

      transition-delay: 2s;
    }
  }

  &.active {

    .video-link {
      @apply hidden;
    }

    .video-info {
      @apply h-auto;
      @apply opacity-75;

      span {
        @apply transform translate-y-0;
      }
    }
  }
}

/* purgecss end ignore */
