.b-grid {

  &--preset-6 {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full;

      @screen lg {
        &:nth-child(odd) {
          width: 60%;
        }

        &:nth-child(even) {
          width: 40%;
        }
      }
    }
  }
}
