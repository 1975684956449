.availability {
  @apply text-sm flex justify-center items-center flex-no-wrap align-middle;
  @apply font-400 mt-2;

  &::before {
    @apply rounded-full mr-2;

    background-color: #43b02a;
    content: '';
    height: 18px;
    width: 18px;
  }

  &--none {

    &::before {
      background-color: #ed2939;
    }
  }
}
