@import 'preset-1', 'preset-2', 'preset-3', 'preset-4-5', '_preset-6';

$slideSpacing: 2rem;

/* purgecss start ignore */

.slider {
	@apply relative flex justify-end items-stretch h-full;

	.slider--autoplay & {
		@apply block;
	}

	.card {
		@apply h-full select-none;

		&__title h3 {
      text-wrap: balance;
      min-height: 80px;
			@media (min-width: 1280px) {
				font-size: 1.5rem;
			}
		}
		&__description {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-wrap: pretty;
		}
	}
}

@if $rtl {
	.tns-inner {
		direction: initial;
	}
}

.tns-nav {
	@apply text-center py-6;

	button {
		@apply bg-brand-3 rounded-full border-0 inline-block h-3 w-3 m-1 align-middle outline-none;

		transition: all 100ms ease-in;

		&:hover {
			@apply bg-brand-1;
		}

		&.tns-nav-active {
			@apply bg-brand-1;
			@apply h-4 w-4;
		}
	}
}

.tns-controls {
	@apply outline-none;

	button {
		@apply outline-none;

		&:disabled {
			@apply opacity-50 cursor-not-allowed;

			.icon {
				@apply text-grey-500;
			}
		}
	}

	&__button {
		@apply inline-block relative ml-2;
		@apply bg-white shadow-lg border border-grey-100;
		@apply h-10 w-10 rounded-full;

		@screen lg {
			@apply h-12 w-12;
		}

		.icon {
			@apply absolute left-50 top-50;
			@apply block h-4 w-4;

			transform: translate(-50%, -50%);
		}

		&:hover {
			@apply shadow-xl;

			.icon {
				@apply text-brand-1;
			}
		}
	}
}

/* purgecss end ignore */
