.band--footer {
  @apply pb-0 z-0;
}

.footer {
  @apply text-center;

  @screen lg {
    @apply text-left;
    @if $rtl {
      @apply text-right;
    }
  }

  &__divider {
    @apply border-t border-grey-300 w-full;

    height: 1px;
  }

  &__payoff {
    @apply px-8 py-8;

    @screen md {
      @apply py-12;
    }
  }

  &__payoff-image {
    @apply block mx-auto w-full;

    max-width: px-to-rem(368px); 
  }

  &__logo {
    @apply block text-center px-8 py-4;

    svg {
      @apply inline-block transform-none;

      max-width: px-to-rem(100px);

      @screen lg {
        max-width: px-to-rem(152px);
      }
    }
  }

  &__title {
    @apply font-700 mb-5 text-base;

    &--subtitle {
      @apply text-sm mt-1;
    }
  }

  &__bottom-menu {
    @apply border-t border-grey-300 pt-6 mt-10 pb-12;

    @screen md {
      @apply text-left flex items-center;
    }

    .menu {
      @apply mb-2;

      @screen md {
        @apply inline-flex mr-10 mb-0;
      }
    }

    .icon {
      @apply w-5 h-5 fill-current text-brand-1;
    }
  }
}
