$slideSpacing: 2rem;

/* purgecss start ignore */

.slider {
  $self: &;

  &--preset-3 {
    width: calc(100% + #{$slideSpacing});

    .slider-content {
      @apply text-center mb-8;

      margin-right: $slideSpacing;

      @screen sm {
        @apply pt-4 mb-12;
      }
    }

    .slide {
      margin-right: $slideSpacing;
    }

    .tns-nav {
      margin-right: $slideSpacing;
    }
  }
}

/* purgecss end ignore */
