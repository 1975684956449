.card {
  $self: &;

  &--preset-1 {
    @apply bg-white;
    display: flex;
    flex-direction: column;

    .item & {
      @apply h-full;
    }

    #{$self}__body {
      @apply flex;
      @apply p-4;
      flex-direction: column;
      height: 100%;
    }

    #{$self}__title {
      @apply w-full;

      @extend .spacing--preset-s;

      @if $rtl {
        @apply text-right #{!important};
      }

      h3 {
        @extend .title--preset-6;

        a {

          &:hover {
            @apply text-brand-2;
          }
        }
      }
    }

    @if $theme =='3' or $theme == '4' {
      #{$self}__image {
        position: relative;

        ::after {
          content: "";
          display: block;
          height: 5px;
          width: 0;
          background-color:var(--theme-color-brand1);
          position: absolute;
          bottom: 1px;
          left: 0;
          transition: width 0.5s ease-in-out;
        }
      }

      &:hover {
        #{$self}__image {
          ::after {
            width: 100%;
          }
        }
      }
    }

    #{$self}__meta {
      @apply w-full order-first;

      @extend .spacing--preset-s;

      #{$self}__date {
        @apply opacity-50;

        @extend .text--preset-small;
        @extend .spacing--preset-s;

        display: flex;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 3px;

          +.icon {
            margin-left: 15px;
          }
        }
      }
    }

    #{$self}__link {
      fill: currentColor;
      margin-top: auto;

      a {

        &:hover {
          fill: $brand-color-1;
        }
      }

      svg {}
    }


  }
}