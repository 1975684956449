@use 'sass:math';

$slideSpacing: 2rem;

/* purgecss start ignore */

.slider {
  $self: &;

  &--preset-2 {
    @screen lg {

      .tns-inner {
        @apply overflow-hidden;
      }
    }

    .figure {
      @apply flex justify-center items-center;
      @apply h-full w-full px-2;

      img {
        @apply mb-4;
      }
    }

    .tns-controls {
      @apply static text-center pt-2;

      margin-right: math.div($arrow-width, 2);

      &__button {
        @apply inline-block relative ml-2;
        @apply bg-white;
        @apply h-8 w-8 rounded-full;

        @screen lg {
          @apply h-10 w-10;
        }

        .icon {
          @apply absolute left-50 top-50;
          @apply block h-4 w-4;

          transform: translate(-50%, -50%);
        }

        &:hover {

          .icon {
            @apply text-brand-1;
          }
        }
      }
    }
  }
}

/* purgecss end ignore */
