.b-collection {
  $self: &;

  &--preset-4 {
    @apply flex flex-wrap;
    @apply max-w-content mx-auto;

    #{ $self }__container {

        .item {
          @apply flex-initial w-full;
          @apply px-2 pb-2 mb-0;
        }

        &--preset-1 {
          @apply w-full;

          @screen lg {
            @apply w-2/3;

            .item {
              height:100%;
            }
          }
        }

        &--preset-2 {
          @apply w-full;

          @screen md {
            @apply flex;
          }

          @screen lg {
            @apply block w-1/3;
          }

          .item {

            @screen md {
              @apply w-1/2;
            }

            @screen lg {
              @apply w-full;
            }

            .figure__title {
              @apply text-basesm;
            }

            .figure__caption {
              @apply text-mini
            }
          }
        }

        &--preset-3 {
          @apply flex flex-wrap w-full; 

          .item {
            @screen md {
              @apply flex-initial w-1/2;
            }

            @screen lg {
              @apply w-1/3;
            }

            .figure__title {
              @apply text-basesm;
            }

            .figure__caption {
              @apply text-mini
            }
          }

        }
      }
    }
  }

