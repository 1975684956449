/* purgecss start ignore */

.countries {
  @apply max-w-full w-full;

  &__map {
    @apply relative text-white max-w-full w-full h-full max-h-full;

    &--kenia {
      @apply p-8 mx-auto max-w-xl;
    }

    &--arabic {
      @apply mx-auto max-w-3xl mb-4;
      // @apply border border-black;
      background: #add8e6;
    }

    // svg {
    //   @apply relative inline-block h-full w-full;

    //   fill: #fff;
    //   stroke: #555;
    //   stroke-width: 1px;
    // }

    // $countries: 'AF','AO','AL','AE','AR','AM','AU','AT','AZ','BI','BE','BJ','BF','BD','BG','BA','BY','BZ','BO','BR',
    //   'BN','BT','BW','CF','CA','CH','CL','CN','CI','CM','CD','CG','CO','CR','CU','CZ','DE','DJ','DK','DO',
    //   'DZ','EC','EG','ER','EE','ET','FI','FJ','GA','GB','GE','GH','GN','GM','GW','GQ','GR','GL','GT','GY',
    //   'HN','HR','HT','HU','ID','IN','IE','IR','IQ','IS','IL','IT','JM','JO','JP','KZ','KE','KG','KH','KR',
    //   'KW','LA','LB','LR','LY','LK','LS','LT','LU','LV','MA','MD','MG','MX','MK','ML','MM','ME','MN','MZ',
    //   'MR','MW','MY','NA','NE','NG','NI','NL','NO','NP','NZ','OM','PK','PA','PE','PH','PG','PL','KP','PT',
    //   'PY','PS','QA','RO','RU','RW','EH','SA','SD','SS','SN','SL','SV','RS','SR','SK','SI','SE','SZ','SY',
    //   'TD','TG','TH','TJ','TM','TL','TN','TR','TW','TZ','UG','UA','UY','US','UZ','VE','VN','VU','YE','ZA',
    //   'ZM','ZW','SO','GF','FR','ES','AW','AI','AD','AG','BS','BM','BB','KM','CV','KY','DM','FK','FO','GD',
    //   'HK','KN','LC','LI','MV','MT','MS','MU','NC','NR','PN','PR','PF','SG','SB','ST','SX','SC','TC','TO',
    //   'TT','VC','VG','VI','CY','RE','YT','MQ','GP','CW','IC','XK', 'KE-28', 'KE-19', 'KE-39', 'KE-94', 'KE-21', 'KE-23', 'KE-30', 'KE-10', 'KE-22', 'KE-13', 'KE-27', 'KE-29', 'KE-16', 'KE-33', 'KE-34', 'KE-02', 'KE-08', 'KE-06', 'KE-15', 'KE-18', 'KE-40', 'KE-36', 'KE-17', 'KE-12', 'KE-41', 'KE-35', 'KE-45', 'KE-31', 'KE-38', 'KE-32', 'KE-26', 'KE-04', 'KE-20', 'KE-11', 'KE-44', 'KE-07', 'KE-03', 'KE-42', 'KE-05', 'KE-01', 'KE-09', 'KE-37', 'KE-47', 'KE-46', 'KE-24', 'KE-25', 'KE-43';

    // @each $country in $countries {

    //   &--active-#{ $country } {

    //     [data-id=#{$country}] {
    //       fill: #43b02a;

    //       &:hover {
    //         fill: lighten(#43b02a, 5%);
    //         stroke: darken(#43b02a, 5%);
    //         transition: fill ease-in-out 120ms;
    //       }
    //     }
    //   }

    //   &--active2-#{ $country } {

    //     [data-id=#{$country}] {
    //       fill: #ddc600;

    //       &:hover {
    //         fill: lighten(#ddc600, 5%);
    //         stroke: darken(#ddc600, 5%);
    //         transition: fill ease-in-out 120ms;
    //       }
    //     }
    //   }

    //   &--active3-#{$country} {

    //     [data-id=#{$country}] {
    //       fill: #cbe18e;

    //       &:hover {
    //         fill: lighten(#cbe18e, 5%);
    //         stroke: darken(#cbe18e, 5%);
    //         transition: fill ease-in-out 120ms;
    //       }
    //     }
    //   }

    //   // &--active4-#{$country} {

    //   //   [data-id=#{$country}] {
    //   //     fill: #f9f8e1;

    //   //     &:hover {
    //   //       fill: lighten(#f9f8e1, 5%);
    //   //       stroke: darken(#f9f8e1, 5%);
    //   //       transition: fill ease-in-out 120ms;
    //   //     }
    //   //   }
    //   // }

    //   // &--active5-#{$country} {

    //   //   [data-id=#{$country}] {
    //   //     fill: #c7b37f;

    //   //     &:hover {
    //   //       fill: lighten(#c7b37f, 5%);
    //   //       stroke: darken(#c7b37f, 5%);
    //   //       transition: fill ease-in-out 120ms;
    //   //     }
    //   //   }
    //   // }
    // }
  }

  &__legenda {
    @apply text-left leading-normal mx-auto;

    @screen sm {
      @apply px-16;
    }

    ul {
      @apply max-w-content-small mx-auto;
      @apply flex flex-wrap items-center;
      @apply text-2xs;

      li {
        @apply block w-full py-1 pr-2 pl-6;
        @apply relative mb-2;

        > span {
          @apply absolute left-0 top-0;
          @apply h-4 w-4 inline-block bg-brand-1 mr-1 mt-1 align-middle;
          @apply border border-black;

          margin-bottom: 2px;

          &.active {
            background-color: #43b02a;
          }

          &.active2 {
            background-color: #ddc600;
          }

          &.active3 {
            background-color: #cbe18e;
          }

          // &.active4 {
          //   background-color: #f9f8e1;
          // }

          // &.active5 {
          //   background-color: #c7b37f;
          // }
        }

        > div:not(.rte) {

          > span {
            @apply block mb-2;

            a {
              @apply underline text-brand-1;

              &:hover {
                @apply text-brand-2;
              }
            }
          }
        }

        > .rte {

          * {
            @apply font-1 text-2xs leading-normal mb-2 #{!important};
          }
        }
      }
    }
  }
}

/* purgecss end ignore */
