.title {

  &--preset-1,
  &--preset-2,
  &--preset-3,
  &--preset-4,
  &--preset-5,
  &--preset-6,
  &--alert {
    @apply font-1 leading-tight font-700;
    position: relative;
    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }



  &--preset-1 {
    @apply text-2xl;

    @screen md {
      @apply text-6xl;
    }

    @screen xl {
      @apply text-7xl;
    }
  }

  &--preset-2 {
    @apply text-xl;

    @screen md {
      @apply text-4xl;
    }

    @screen xl {
      @apply text-5xl;
    }

  }

  &--preset-3 {
    @apply text-lg;

    @screen md {
      @apply text-3xl;
    }

    @screen xl {
      @apply text-4xl;
    }
  }

  &--preset-4 {
    @apply text-lg;

    @screen md {
      @apply text-2xl;
    }

    @screen xl {
      @apply text-3xl;
    }
  }

  &--preset-5 {
    @apply text-lg;

    @screen md {
      @apply text-xl;
    }

    @screen xl {
      @apply text-2xl;
    }
  }

  &--preset-6 {
    @apply text-base;

    @screen md {
      @apply text-lg;
    }

    @screen xl {
      @apply text-xl;
    }
  }
}

@if $theme == '3' or $theme == '4' {
  .title--preset-2,
  .title--preset-3,
  .title--preset-4,
  .title--preset-5 {
    display: inline-block;

    &::after {
      content: "";
      display: block;
      height: 5px;
      width: 100%;
      background-color: var(--theme-color-brand1);
      margin-top: 5px;
    }
  }

  .card .title--preset-2::after,
  .card .title--preset-3::after,
  .card .title--preset-4::after,
  .card .title--preset-5::after,
  .band--header .title--preset-2::after,
  .band--header .title--preset-3::after,
  .band--header .title--preset-4::after,
  .band--header .title--preset-5::after,
  .contact .title--preset-2::after {
    content: none;
  }
}
