.icon-text {
  $self: &;

  &--horizontal {
    @apply flex;

    max-width: none;

    #{$self} {

      &__icon {
        @apply p-0 mr-4;

        width: px-to-rem(72px);

        svg {
          @apply block m-auto w-full fill-current;

          max-height: px-to-rem(72px);
          max-width: px-to-rem(72px);
        }
      }

      &__content {
        @apply text-left;
        @apply pt-2;
      }

      &__title {
        @apply font-700 w-full text-basesm leading-snug mb-2;
      }

      &__text {
        @apply text-2xs w-full leading-snug;
      }
    }
  }
}
