.market-tags {

  &__title {
    @apply mb-4 text-lg font-700;
  }

  &__groups {
    @screen md {
      @apply flex flex-wrap;
    }
  }

  &__group {
    @screen md {
      @apply w-1/2;
    }

    @screen lg {
      @apply w-full;
    }

    @screen xl {
      @apply w-1/2;
    }
  }
}
