/* purgecss start ignore */

#cookiebanner {
  position: fixed;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .9) !important; /* stylelint-disable-line declaration-no-important */
  color: #333;
  opacity: 1;
  z-index: 2147483645;
  overflow: auto;
  max-height: calc(100% - 2rem);
  width: calc(100% - 2rem);

  @apply shadow-xl p-4 max-w-content-small;
  @apply border-2 border-brand-2;

  backdrop-filter: blur(5px);

  @apply left-0 right-0 bottom-0 m-4;

  @screen sm {
    @apply p-8;
  }

  @screen md {
    @apply left-50 right-auto mx-0;
    @apply transform -translate-x-1/2;
  }
}

#cookiebanner .c-header {
  @extend .title--preset-5;
  @apply mb-2;
}

#cookieBannerLink{
  @apply hidden;
}

#cookiebanner p.c-message {
  @extend .text--preset-body;
}

#cookiebanner a {
  @apply text-brand-2;
}

#cookiebanner a:hover, a:visited, a:active{
  text-decoration: none;
}

#cookiebanner a.c-button {
  @extend .button;
  @extend .button--preset-1;

  @apply justify-center;
}

.button-container {
text-align: center;
margin-top: 20px;
}

#cookiebanner a.c-button, #cookiebanner a.s-button {
  border-width: 0;
  outline: none;
  border-radius: 5px;
  padding: 9px 30px 11px;
  cursor: pointer;
  word-break: normal;
}

#cookiebanner a.s-button {
  @apply text-brand-2 underline;
  vertical-align: -webkit-baseline-middle;

  &:hover {
    @apply no-underline;
  }
}

#cookiebanner .c-settings {
  word-wrap: break-word;
  display: none;
}

#cookiebanner .c-content {
  @apply border-t-2 border-grey-300;
  @apply my-4 pt-4;

  @screen md {
    @apply mt-8 pt-8;
  }
}

#cookiebanner .c-intro {
  display:list-item;
  @apply list-none;
  @apply mb-4 pl-4;
  margin-left: 40px;
}

#cookiebanner .c-switch-heading {
  @apply font-1 leading-tight font-700;
  @apply text-brand-2 text-basesm;
  @apply mb-2;
  @apply pl-4;
}

.switch {
position: relative;
display: inline-block;
width: 40px;
height: 22px;
}

.switch input {
opacity: 0;
width: 0;
height: 0;
}

#cookiebanner .slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color:#B4B4B4;
-webkit-transition: .2s;
transition: .2s;
}

#cookiebanner .slider:before {
position: absolute;
content: "";
height: 18px;
width: 18px;
left: 2px;
bottom: 2px;
background-color: white;
-webkit-transition: .2s;
transition: .2s;
}

#cookiebanner input:checked + .slider {
  @apply bg-brand-2;
}

#cookiebanner input:checked[disabled] + .slider {
background-color: grey;
}

#cookiebanner input:checked + .slider:before {
-webkit-transform: translateX(18px);
-ms-transform: translateX(18px);
transform: translateX(18px);
}

#cookiebanner .slider.round {
border-radius: 18px;
}

#cookiebanner .slider.round:before {
border-radius: 50%;
}

@media screen and (max-width:720px) {
  .button-container a {display: block;text-align: center;}
}

@media screen and (max-width:720px) {
  #cookiebanner a.c-button {
    width:100%;
    box-sizing:border-box;
    text-align:center;
    margin-bottom:20px;}
  #c-right {
    float:none;
  }
  #c-left {
    float:none;
  }
}

@media screen and (min-width:720px) {
  #cookiebanner #c-left {
    float:left;
    max-width:80%;
  }
  #cookiebanner #c-right {
    float:right;
  }
}


.CookieDeclaration {
  @apply max-w-content;

  p {
    @extend .text--preset-body;
  }
}

#CookieDeclarationUserStatusLabel {
  @extend .text--preset-body;
  @extend .spacing;

  @apply p-2 border-2 border-brand-1 font-800 rounded-sm;
}

#CookieDeclarationChangeConsentChange {
  @extend .button;
  @extend .button--preset-1;
}

#CookieDeclarationChangeConsentWithdraw {
  @extend .text--preset-small;
  // @extend .link;
  @extend .link--preset-1;
}

#CookieDeclarationDoNotSell {
  @extend .text--preset-body;

  @apply mt-4 font-700;
}

.CookieDeclarationDialogText,
.CookieDeclarationIntro,
.CookieDeclarationLastUpdated {
  @extend .spacing;

  a {
    @apply text-brand-2 underline;

    &:hover,
    &:focus {
      @apply no-underline;
    }
  }
}

.CookieDeclarationType {
  @apply block my-4 p-2 pb-0;
  @apply align-top;
  @apply shadow-lg rounded-sm;
  @apply border-2 border-grey-400 #{!important};

  // display: block;
  // margin: 12px 0 12px 0;
  // padding:8px 8px 0 8px;
  // border: 1px solid #333333;
  // vertical-align: top;

  p {
    @extend .text--preset-small;

    &.CookieDeclarationTypeHeader {
      @extend .title;
      @extend .title--preset-5;

      @apply my-2;
    }
  }
}

.CookieDeclarationTypeDescription {
  margin: 2px 0 16px 0;
}

.CookieDeclarationTable {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font: inherit;
  font-size: 100%;
  margin: 0 0 18px 0;
  padding: 0;
  table-layout: fixed;
  vertical-align: baseline;
  width: 100%;
}

.CookieDeclarationTableHeader {
  @apply border-b-2 border-grey-400 #{!important};
  @apply p-1 pb-2 overflow-hidden;
  @apply text-left font-700 font-1 text-xs text-brand-2;

  @screen md {
    @apply text-sm;
  }
}

.CookieDeclarationTableHeader[dir='rtl'] {
  text-align: right;
}

.CookieDeclarationTableCell {
  text-overflow: ellipsis;
  word-wrap: break-word;

  @apply text-2xs;
  @apply border-b border-grey-300 #{!important};
  @apply align-top p-1;
}

#CookieDeclarationUserStatusLabelConsentId {
  text-overflow: ellipsis;
  word-wrap: break-word;
}

#CookieDeclarationUserStatusLabel dt,
#CookieDeclarationUserStatusLabel dd {
  display: inline-block;
}

#CookieDeclarationUserStatusLabel dt {
  font-weight: 400;
  margin-bottom: .5rem;
  margin-left: 0;
  padding-right: 4px;
}

@media all and (max-width: 600px) {
  .CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n+5),
  .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n+5),
  .CookieDeclaration table.CookieDeclarationTable colgroup col:nth-child(n+5) {
    display: none;
  }
}

/* purgecss end ignore */

