.contact {
  @apply px-4 py-8;
  background-color: #edea9c;

  @if $theme =='3' {
    background-color: #ffefcc;
  }

  @if $theme =='4' {
    background-color: var(--theme-color-brand3);
  }

  @screen md {
    @apply flex px-16 py-20;
  }

  &__content {
    @apply text-black;

    @if $theme =='4' {
      @apply text-white;
    }

    @screen md {
      @apply flex-shrink-0;
      width: 65%;
    }
  }

  &__avatar {
    @apply pb-2;

    @screen md {
      @apply pb-0 pr-8 flex-shrink;
      align-items: center;
      display: flex;
    }

    @screen lg {
      @apply pr-16;
    }

    img {
      @apply block p-0 mb-2 mx-auto rounded-full w-full;
      max-width: px-to-rem(200px);

      @screen md {
        @apply mb-0;
        max-width: px-to-rem(540px);
      }
    }
  }

  &__title {}

  &__text {
    &.rte {
      @apply mb-6;

      >* {
        line-height: 1.5rem;

        @screen md {
          line-height: 2rem;
        }
      }

      *:last-child {
        @apply mb-0;
      }
    }
  }

  &__options {
    @screen md {
      >*+* {
        @apply ml-6;
      }
    }
  }

  &__name {
    @apply font-700 text-lg;
  }

  &__function {
    @apply inline-block mb-1;
  }

  &__label {
    @apply inline-block pr-1;
  }

  &__email {
    @apply inline-block mb-1;
  }

  &__telephone {
    @apply inline-block mb-1;
  }

  &__link {
    @apply font-700 text-brand-2;

    @if $theme =='4' {
      @apply font-700 text-black;
    }


    &:hover {
      @apply text-brand-1 underline;

      @if $theme =='4' {
        @apply text-black underline;
      }
    }
  }

  &__button {
    @apply mt-5;
  }
}

.sticky-contact {
  @apply bottom-0 right-0 shadow-md z-50 px-4 py-3;
  width: 100%;
  background-color: #edea9c;
  box-shadow: 0px -1px 40px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  pointer-events: auto;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    left: auto;
    max-width: 340px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: auto;
  }

  &__container {
    @apply fixed;
    max-width: 1920px;
    position: fixed;
    width: 100%;
    bottom: 0;
    pointer-events: none;
    z-index: 100;
    left: 0;

    @media (min-width: 768px) {
      transform: translateX(-50%);
      left: 50%;
    }
  }

  &__title {
    @apply mt-0 text-sm font-700;
    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    flex-grow: 1;
    align-items: center;

    @screen sm {
      justify-content: space-evenly;
    }
  }

  &__avatar {
    @apply mr-4 overflow-hidden rounded-full;
    max-width: 45px;

    @screen sm {
      @apply mr-2;
      max-width: 70px;
    }

    img {
      @apply w-full h-full object-cover;
    }
  }

  &__email {
    @apply flex items-center;

    & .sticky-contact__label {
      @apply text-lg font-700 mr-1;
    }
  }

  &__link {
    @apply text-black no-underline;
  }

  .icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: auto;

    @screen sm {
      @apply hidden;
    }
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.2);
  }
}