@import 'embed';

.video-player {
  @apply h-0 mx-auto overflow-hidden relative w-full;

  padding: 0 0 56.25%;

  iframe,
  object,
  embed,
  video {
    @apply absolute left-0 top-0;
    @apply rounded;

    height: 100% !important; /* stylelint-disable-line declaration-no-important */
    width: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}
