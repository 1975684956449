/* purgecss start ignore */

.band--notification {
  @apply py-0 font-2 font-500 text-mini text-center;
  @apply bg-brand-1 text-white border-b border-white;
  @apply hidden;

  text-shadow: 0 0 10px rgba(0, 0, 0, .25);

  &--style1 {
    @apply bg-brand-1;
  }

  &--style2 {
    @apply bg-status-ok;
  }

  &--style3 {
    @apply bg-brand-4;
  }

  .band__content {
    @apply max-w-full;

    a {
      @apply underline;

      &:hover {
        @apply no-underline;
      }
    }
  }

  body:not(.body--hide-notification) & {
    @apply block;
  }

  @screen sm {
    @apply text-xs;
  }

  @screen md {
    @apply text-sm;
  }

  .notification {
    @apply max-w-full;
  }

  .b-grid--notification {
    @apply flex-no-wrap items-center justify-between;

    .col:first-child {
      @apply flex-auto;
    }

    .col:last-child {
      @apply flex-initial;
    }
  }

  .button {
    @apply p-1 border-0 bg-transparent text-white;

    .icon {
      height: 1.2rem;
      width: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;


      svg {
        @apply fill-current;
      }
    }

    span {
      @apply mr-0;
    }
  }
}

@keyframes animation-slideClose {

  from {
    max-height: 240px;
    opacity: 1;
  }

  to {
    max-height: 0;
    opacity: 0;
  }
}

.notification-close {
  animation-duration: .48s;
  animation-name: animation-slideClose;
}

/* purgecss end ignore */
