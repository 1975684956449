.list {

    $self: &;
  
    &--preset-6 {
      @apply font-2 leading-normal text-xs;
  
      @screen md {
        @apply text-sm;
      }
  
      li {
        @apply mb-1;
  
        &::before {
          @apply text-brand-1;
        }
      }
  
      &#{$self}--icon {
  
        li {
  
          &::before {
            @apply hidden;
          }
  
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2343b02a' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E");
          }
        }
      }
      
      // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
    }
  }
  