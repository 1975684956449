/* purgecss start ignore */

.menu {

  $self: &;

  &--preset-5 {
    @apply flex flex-wrap justify-end;

    > #{$self}__item {
      @apply w-1/2 pr-2;
      @apply my-2;
      @apply text-xs;

      @include mq(640, max) {
        @apply text-sm;
      }

      @screen sm {
        @apply w-auto mr-4 my-1 pr-0;
      }

      @screen md {
        @apply mr-0 ml-4;
      }

      > a {
        @apply inline-block whitespace-normal;

        @extend .link--preset-menu;

        @include mq(640, max) {
          @apply text-white;

          &:hover {
            @apply text-white opacity-80;
          }
        }

        &[href^='tel:'] {
          @apply text-brand-1 font-700 underline;

          &:hover {
            @apply no-underline;
          }

          &::before {
            @apply inline-block h-3 w-3 mr-1 align-middle;
            @apply bg-contain bg-center bg-no-repeat;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{rgba($brand-color-1, .99)}' d='M497.39 361.8l-112-48a24 24 0 00-28 6.9l-49.6 60.6A370.66 370.66 0 01130.6 204.11l60.6-49.6a23.94 23.94 0 006.9-28l-48-112A24.16 24.16 0 00122.6.61l-104 24A24 24 0 000 48c0 256.5 207.9 464 464 464a24 24 0 0023.4-18.6l24-104a24.29 24.29 0 00-14.01-27.6z'%3E%3C/path%3E%3C/svg%3E");
            content: '';
          }
        }

        &[target='_blank'] {

          &::after {
            @apply inline-block h-4 w-4 ml-1;
            @apply bg-contain bg-center bg-no-repeat;
            @apply opacity-80;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath d='M448 279.196V464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h248a24 24 0 0 1 16.97 7.029l16 16C344.09 102.149 333.382 128 312 128H64v320h320V295.196c0-6.365 2.529-12.47 7.029-16.971l16-16C422.148 247.106 448 257.814 448 279.196zM576 37.333C576 16.715 559.285 0 538.667 0H380c-15.464 0-28 12.536-28 28v17.885c0 15.766 13.011 28.424 28.772 27.989l67.203-1.906L199.09 319.09c-9.429 9.363-9.457 24.605-.061 34.001l23.879 23.879c9.396 9.396 24.639 9.369 34.001-.06l247.122-248.885-1.906 67.203c-.434 15.76 12.224 28.772 27.99 28.772H548c15.464 0 28-12.536 28-28V37.333z'/%3E%3C/svg%3E");
            content: '';
          }
        }

        &:hover {

          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='#{rgba($brand-color-1, .99)}' d='M448 279.196V464c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h248a24 24 0 0 1 16.97 7.029l16 16C344.09 102.149 333.382 128 312 128H64v320h320V295.196c0-6.365 2.529-12.47 7.029-16.971l16-16C422.148 247.106 448 257.814 448 279.196zM576 37.333C576 16.715 559.285 0 538.667 0H380c-15.464 0-28 12.536-28 28v17.885c0 15.766 13.011 28.424 28.772 27.989l67.203-1.906L199.09 319.09c-9.429 9.363-9.457 24.605-.061 34.001l23.879 23.879c9.396 9.396 24.639 9.369 34.001-.06l247.122-248.885-1.906 67.203c-.434 15.76 12.224 28.772 27.99 28.772H548c15.464 0 28-12.536 28-28V37.333z'/%3E%3C/svg%3E");
          }
        }
      }

      &--selected {

        > a {
          @apply text-brand-2 font-700;
        }
      }
    }
  }
}

/* purgecss end ignore */
