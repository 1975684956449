.breadcrumb-detail {
  @apply text-xs;

  @screen md {
    @apply flex items-center;
  }

  &__back {
    @apply flex items-center font-700 mr-6 mb-2;

    @if $rtl {
      @apply mr-0 ml-6;
    }

    @screen md {
      @apply mb-0;
    }

    &:hover {
      @apply text-brand-1;
    }

    .icon {
      @apply inline-block w-4 h-4 mr-2;
    }
  }
}
