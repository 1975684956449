
.product {
  @apply border border-grey-200 border-solid px-4 py-6 block bg-white ;
  width: 100%;
  &__image {
    @apply text-center;

    img {
      @apply w-full;
    }
  }

  &__image--has-overlay {
    @apply relative;
  
  }

  &__overlay {
    @apply block w-1/3;   

    @container overwiew (max-width: 767px) {
      max-width: px-to-rem(60px);   
    }
    @container overwiew (min-width: 768px) {
      max-width: px-to-rem(70px);   
    }
    @container overwiew (min-width: 1200px) {
      max-width: px-to-rem(90px);   
    }
  }

  &__content {

  }

  &__button {
    @apply mt-4 flex justify-end;
  }
}

a.product {

  &:hover {
    @apply shadow-xl;
  }
}
