/* ubuntu-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ubuntu-v20-latin-regular.woff2') format('woff2');
}
/* ubuntu-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/ubuntu-v20-latin-italic.woff2') format('woff2');
}
/* ubuntu-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/ubuntu-v20-latin-500.woff2') format('woff2');
}
/* ubuntu-500italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/ubuntu-v20-latin-500italic.woff2') format('woff2');
}
/* ubuntu-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/ubuntu-v20-latin-700.woff2') format('woff2');
}
/* ubuntu-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/ubuntu-v20-latin-700italic.woff2') format('woff2');
}


// THEME1: ''
$brand-color-1: #ed2939;
$brand-color-2: #001489;
$brand-color-3: #f9f8e1;
$brand-color-4: #c7b37f;
$brand-color-5: #f9f8e1;

// SUBTHEME COLORS
$sub-color-1: #c4d600;
$sub-color-2: #00843d;
$sub-color-3: #212721;
$sub-color-4: #888b8d;

@if $theme == '2' {
  // THEME2: ''
  $brand-color-1: #8e9730;
  $brand-color-2: #788028;
  $brand-color-3: #03dac6;
}

@if $theme == '3' {
  // THEME3: ''
  $brand-color-1: #FBBA00;
  $brand-color-2: #d59e00;
  $brand-color-3: #03dac6;
}

@if $theme == '4' {
  // THEME4: ''
  $brand-color-1: #E5005B;
  $brand-color-2: #c3004d;
  $brand-color-3: #8F96B2;
}

:root {
  --theme-color-brand1: #{$brand-color-1};
  --theme-color-brand2: #{$brand-color-2};
  --theme-color-brand3: #{$brand-color-3};
  --theme-color-brand4: #{$brand-color-4};
  --theme-color-brand5: #{$brand-color-5};
  --sub-color-1: #{$sub-color-1};
  --sub-color-2: #{$sub-color-2};
  --sub-color-3: #{$sub-color-3};
  --sub-color-4: #{$sub-color-4};  
}
