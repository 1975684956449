@import 'rte', 'alt-styling';

/* purgecss start ignore */

.content,
.rte {
  @apply max-w-content-small;
  // @apply mx-auto;

  .text-center & {
    @apply text-center;

    ul,
    ol {
      @apply inline-block mx-auto text-left;

      & + ol,
      & + ul {
        @apply table m-auto mb-4;
      }
    }
  }

  .button + .button {
    @apply mb-3;
  }
}

.text-center .band__content {

  .list--preset-1 {
    @apply inline-block mx-auto text-left;

    & + ol,
    & + ul {
      @apply table m-auto mb-4;
    }
  }
}

/* purgecss end ignore */
