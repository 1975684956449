.b-grid {

  &--preset-9 {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full z-20 relative;

      @screen lg {
        @apply w-auto flex-initial;

        &:first-child {
          max-width: 40%;
        }
      }

      &:nth-child(even) {
        @apply hidden z-10 relative;

        @screen lg {
          @apply block flex-auto;

          .countries {
            @apply -ml-24 -mr-4 -mt-8;
            @apply max-w-site w-auto;
          }
        }

        @screen xl {

          .countries {
            @apply -ml-32 -mt-16;
          }
        }
      }
    }
  }
}
