.text {

  &--preset-body,
  &--preset-intro,
  &--preset-small,
  &--preset-caption,
  &--preset-card,
  &--preset-quote {
    @apply font-2 leading-double;

    // ALTERNATIVE STYLING FOR CONTENT: 2_molecules/content/_alt-styling.scss
  }

  &--preset-card,
  &--preset-body {
    @apply text-xs;

    @screen md {
      @apply text-sm;
    }
  }

  &--preset-intro {
    @apply text-sm ;

    @screen md {
      @apply text-base;
    }
  }

  &--preset-small {
    @apply text-xs;
  }

  &--preset-xsmall {
    @apply text-mini;
  }

  &--preset-caption {
    @apply text-mini;

    @screen md {
      @apply text-xs;
    }
  }

  &--preset-quote {
    @apply italic leading-snug;
    @apply text-base;

    @screen md {
      @apply text-lg;
    }
  }
}
