.social {
  @apply block;

  &__item {
    @apply inline-block;
  }

  &__name {
    @apply hidden;
  }

  &__link {
    @apply block w-5 h-5 mr-1 fill-current;

    &--twitter {
      color: #00aded;
    }

    &--facebook,
    &--facebook-square {
      color: #3b5998;
    }

    &--instagram {
      color: #517fa4;
    }

    &--linkedin {
      color: #007bb6;
    }

    svg {
      @apply w-5 h-5;
    }

    &:hover {
      @apply text-brand-1;
    }
  }
}
