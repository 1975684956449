.paging {
  $self: &;

  &--preset-1 {

    #{$self}__list {
      @apply text-center;
      @apply flex flex-wrap justify-center items-center;

      @extend .text--preset-body;
    }

    #{$self}__item {
      @apply mx-1;

      &--prev,
      &--next {
        @apply hidden;

        @extend .text--preset-small;

        @screen sm {
          @apply block;
        }
      }
    }

    #{$self}__link {
      @apply px-2 py-1 block;
      @apply bg-white border-2 border-grey-200;
      @apply rounded-sm;

      @screen sm {
        @apply px-3;
      }

      &:hover {
        @apply bg-grey-100 border-grey-300;
      }

      &--selected {
        @apply border-2 border-brand-2 text-brand-2 cursor-default;

        @include strongTextClass();

        &:hover {
          @apply bg-white border-brand-2 text-brand-2;
        }
      }
    }

    #{$self}__text {
      @apply px-3 py-1 block border-2 border-grey-100 bg-grey-100 text-grey-400 cursor-default;
      @apply rounded-sm;
    }

    #{$self}__item--divider #{$self}__text {
      @apply px-1;

      @screen md {
        @apply px-2;
      }
    }
  }
}
