.b-grid {

  &--preset-10 {
    @apply flex-wrap -mt-8 bg-white;

    @screen md {
      @apply flex-no-wrap mt-0 bg-transparent;
    }

    & > .col {
      @apply w-full;

      @screen lg {
        @apply w-3/4 flex-auto;
      }

      &:last-child {
        @apply order-first;

        @screen md {
          @apply w-1/4 flex-shrink-0 order-last;

          min-width: 280px;
        }

        @screen lg {
          min-width: 360px;
        }

        @screen xl {
          min-width: 440px;
        }
      }
    }
  }
}
