/* purgecss start ignore */
@import 'locations';

.toggle {
  @apply block overflow-hidden;
  @apply border-t  border-grey-300;
  max-width: 960px;

  &:last-of-type {
    @extend .spacing;
  }

  &__content {
    @apply block pr-10 opacity-0 h-0;
    transition: opacity ease-in-out 160ms, height ease-in-out 160ms;   
  }

  &__title {
    @apply relative block py-2;
    @apply text-brand-2 text-base font-700;
    @apply cursor-pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &::after {
      @apply absolute right-0;
      @apply bg-center bg-contain bg-no-repeat;
      @apply h-6 w-6 block;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{rgba($brand-color-1, .99)}' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'%3E%3C/path%3E%3C/svg%3E");
      content: '';
      top: px-to-rem(12px);
      margin-top: .25rem;
      transition: transform 120ms ease-in-out;
    }

    &--rotated::after {
      transform: rotate(180deg);
      transition: transform 0.5s ease-in-out;
    }

    span {
      @apply inline-block cursor-pointer pr-10;
    }
  }

}
/* purgecss end ignore */