.b-grid {

  &--preset-4 {
    @apply flex-wrap;

    @screen lg {
      @apply flex-no-wrap;
    }

    & > .col {
      @apply w-full max-w-xl;

      @screen sm {
        @apply flex-initial w-1/2 ;
      }

      @screen lg {
        @apply w-1/4;
      }
    }
  }
}
