.team {
  @apply my-8;
  @apply -mx-2;
  @apply flex flex-wrap;

  &__item {
    @apply relative;
    @apply px-2 py-2;
    @apply w-1/2;

    @screen md {
      @apply w-1/3;
    }

    @screen lg {
      @apply w-1/4;
    }

    @screen xl {
      @apply w-1/6;
    }
  }

  &__quote {
    @apply absolute;
    @apply flex items-center flex-wrap;
    @apply bg-brand-1 text-center text-white;
    @apply opacity-0 p-6;
    @apply transition-opacity ease-in-out duration-150;

    bottom: px-to-rem(10px);
    font-size: px-to-rem(13px);
    left: px-to-rem(10px);
    right: px-to-rem(10px);
    top: px-to-rem(10px);

    @screen md {
      font-size: px-to-rem(14px);
    }

    @screen xl {
      @apply text-xs;
    }

    &:hover {
      @apply opacity-100;
    }
  }

  &__description {
    @apply absolute left-0 bottom-0;
    @apply text-white text-left;
    @apply w-full pointer-events-none p-8;

    text-shadow: 1px 1px 4px rgba(0, 0, 0, .7);
  }

  &__title {
    @apply w-full;
    @apply font-700;
  }

  &__function {
    @apply w-full;

    font-size: px-to-rem(13px);
  }

  .figure {
    @apply block;

    img {
      @apply w-full;
    }
  }
}
