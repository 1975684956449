.potato {
  $self: &;

  @apply flex items-center;

  &__icon-container {
    @apply relative flex-shrink-0  text-white mr-4;
    @apply w-10 h-16;

    @screen md {
      @apply w-16 h-20;
    }
  }

  &__icon {
    @apply w-full h-full pl-2 pr-1;

    svg {
      @apply block m-auto w-full fill-current;

      max-height: px-to-rem(30px);
      max-width: px-to-rem(30px);

      @screen md {
        max-height: px-to-rem(40px);
        max-width: px-to-rem(40px);
      }
    }
  }

  &__icon-background {
    color: $brand-color-2;
    @if $theme == '2' {
      color: $brand-color-1; 
    }
    @if $theme == '3' {
      color: $brand-color-1; 
    }

    @if $theme == '4' {
      color: $brand-color-3; 
    }


    svg {
      @apply block absolute bg-center bg-no-repeat top-0 fill-current;
      @apply w-10 h-16;

      @screen md {
        @apply w-16 h-20;
      }
    }

    #{ $self }--market-1 & {
      color: $brand-color-1;
    }

    #{ $self }--market-2 & {
      color: $brand-color-2;
    }

    #{ $self }--market-3 & {
      color: #43b02a;
    }

    #{ $self }--market-4 & {
      color: #00a1de;
    }

    #{ $self }--market-5 & {
      color: #af9800;
    }

    #{ $self }--market-sub-1 & {
      color: $sub-color-1;
    }

    #{ $self }--market-sub-2 & {
      color: $sub-color-2;
    }

    #{ $self }--market-sub-3 & {
      color: $sub-color-3;
    }

    #{ $self }--market-sub-4 & {
      color: $sub-color-4;
    }
  }

  &__content {
    @apply flex-shrink max-w-2xl text-left;
  }

  &__title {
    @apply font-700 w-full text-basesm leading-snug mb-2;
  }

  &__text {
    @apply text-2xs w-full leading-snug;
  }
}

@import 'preset-1';
